const ClickBar = (props) => {
  const buttonStyle = {
    position: "relative",
    overflow: "hidden",
    borderRadius: "16px",
    backgroundColor: "#141414",
    color: "white",
    fontWeight: "600",
    height: "16px",
    width: "64px",
    border: "none",
    transition: "all 0.3s ease-in-out",
  };

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: props.color,
    transform: `translateX(${
      (props.clickcount / props.maxClicks) * 100 - 100
    }%)`,
    transition: "transform 0.1s ease-out",
  };

  return (
    <div style={buttonStyle}>
      <div style={backgroundStyle} />
    </div>
  );
};

export default ClickBar;
