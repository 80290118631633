const SpinningWindow = ({
  motion,
  t,
  spinWeight,
  spinTransform,
  randomAuras,
  spinSpeed,
}) => {
  return (
    <>
      <div className="show-win-backdrop"></div>
      <div className="spin-window">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            duration: 0.1,
          }}
          className="spin-window-container"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
              marginTop: "0px",
              fontSize: "20px",
            }}
          >
            <div className="spin-window-spin-text">{t("spinning")}</div>
            <div className="spin-window-luck-text">{`${t("SpinLuck")}${
              spinWeight > 1 ? spinWeight : 1
            }`}</div>
          </div>

          <div className="spin-window-auras-container">
            <div
              style={{
                transition: `transform ${spinSpeed.current}ms cubic-bezier(0.215, 0.61, 0.355, 1)`,
                transform: `translateX(${spinTransform}px)`,
              }}
              className="spin-window-auras"
            >
              {randomAuras &&
                randomAuras.map((aura) => {
                  return (
                    <div
                      key={aura.key}
                      style={{ backgroundColor: `${aura.rarityColor}` }}
                      className="spin-window-aura"
                    >
                      <div className="a-top">
                        <div className="a-name">{t(aura.title)}</div>
                        <div className="a-probability">
                          1/{aura.probability}
                        </div>
                      </div>
                      <div
                        style={{
                          height: "120px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="bg-star"
                          className="glowing-bg-star"
                          src="/glowing-star.webp"
                        ></img>
                        <img
                          alt="mini-img"
                          style={{ position: "absolute" }}
                          width={"100px"}
                          src={aura.miniimg}
                        ></img>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default SpinningWindow;
