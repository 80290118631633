const ShopIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M22.3,15.2c0-0.1-0.2-0.1-0.2,0l-0.5,6.3c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0,0.7,0c0.9-0.5,1.2-1.5,0.9-2.4
		C23.4,18,22.8,16.5,22.3,15.2z"
      />
      <path d="M17.8,16.7L17,20c0,0.1,0.1,0.3,0.2,0.2l1.9-0.3c0.1,0,0.2-0.2,0.2-0.3l-1.1-3C18.1,16.5,17.8,16.5,17.8,16.7z" />
      <path
        d="M28.4,9.5h-5.6c-0.3-4.5-1.5-8.1-7.4-8.1S8.4,5,8,9.5H2.5c-0.4,0-0.7,0.4-0.7,0.8l3.6,18.1c0.3,1.5,1.6,2.6,3.2,2.6h13.5
		c1.5,0,2.8-1.1,3.2-2.6L29,10.3C29.1,9.9,28.8,9.5,28.4,9.5z M20.2,20.4c-0.1,0.3-0.4,0.4-0.7,0.5l-2.4,0.4c-0.1,0-0.1,0-0.2,0
		c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.6-0.3-0.9l1-4.3c0.1-0.4,0.5-0.8,0.9-0.8c0.5,0,0.9,0.2,1,0.7l1.4,3.9
		C20.4,19.8,20.4,20.2,20.2,20.4z M15.4,2.4c5.7,0,4.1,4.2,4.4,7.1h-8.7C11.4,6.6,9.7,2.4,15.4,2.4z M7.3,25.4l-0.6-0.8
		c3.9-3.2,3.7-5.8,3.5-6.8l-2.6,0.6l-0.1,0c-0.4,0-0.7-0.2-0.9-0.6c-0.2-0.3-0.1-0.7,0.1-1l2.2-2.7l0.8,0.6l-2.2,2.7l3.2-0.8
		l0.2,0.4C11,17.2,12.8,21,7.3,25.4z M14.6,25.3l-1-0.1l0.5-4.8l-1.2,0.8l-0.2,2.6l-1-0.1l0.1-1.9L11.5,22L11,21.2l1-0.6l0.4-6.1
		l1,0.1L13,19.9l1.2-0.8l0.5-5.4l1,0.1l-0.5,4.6l0.4-0.2l0.5,0.8l-1,0.7L14.6,25.3z M23.3,22.6l-0.1,0.1l-0.1,0c-0.5,0-1,0-1.5,0
		l-0.4,5.3l-1-0.1l0.4-5.2c-2.1,0.1-3.8,0.5-4.1,1.1c-0.1,0.3,0.2,0.8,0.8,1.3l-0.7,0.8c-1-0.9-1.4-1.7-1.1-2.4
		c0.5-1.3,3-1.7,5.1-1.8l0.5-7c0-0.5,0.4-0.9,0.9-0.9c0.5-0.1,0.9,0.2,1.1,0.6c0.5,1.4,1.2,3.1,1.7,4.4
		C25.3,20.3,24.7,21.9,23.3,22.6z"
      />
    </g>
  </svg>
);

export default ShopIcon;
