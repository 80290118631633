const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <path d="M26.6,25.9H5.4c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h21.3c1.2,0,2.2,1,2.2,2.2v0C28.8,24.9,27.8,25.9,26.6,25.9z" />
    <path d="M26.6,18.2H5.4c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h21.3c1.2,0,2.2,1,2.2,2.2v0C28.8,17.2,27.8,18.2,26.6,18.2z" />
    <path d="M26.6,10.4H5.4c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h21.3c1.2,0,2.2,1,2.2,2.2v0C28.8,9.4,27.8,10.4,26.6,10.4z" />
  </svg>
);

export default MenuIcon;
