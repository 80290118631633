// ToastNotification.jsx
import React from "react";
import "./Toast.css";
import { motion, AnimatePresence } from "framer-motion";

const ToastNotification = ({ toasts }) => {
  return (
    <div className="toast-container">
      <AnimatePresence>
        {toasts.map((toast) => (
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={toast.id}
            className={`toast ${toast.type}`}
          >
            {toast.message}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastNotification;
