const forgeItems = [
  {
    title: "Base element",
    color: "#242424",
    miniimg: "item-images/120px/base.webp",
    type: "craft",
    needItems: [
      { title: "Stone", type: "aura", quantity: 1 },
      { title: "Mossy", type: "aura", quantity: 1 },
      { title: "Malachite", type: "aura", quantity: 1 },
      { title: "Uncommon-GEM", type: "aura", quantity: 1 },
    ],
    cost: 1000,
  },
  {
    title: "Silver ring",
    color: "#31e70c",
    miniimg: "ring-images/120px/silver.webp",
    type: "ring",
    needItems: [
      { title: "Malachite", type: "aura", quantity: 3 },
      { title: "Uncommon-GEM", type: "aura", quantity: 2 },
      { title: "Rare-GEM", type: "aura", quantity: 1 },
    ],
    cost: 10000,
  },
  {
    title: "Green ring",
    color: "#bb2b2b",
    miniimg: "ring-images/120px/green.webp",
    type: "ring",
    needItems: [
      { title: "Demantoid", type: "aura", quantity: 1 },
      { title: "Dispersion", type: "aura", quantity: 1 },
      { title: "Koshimitsu", type: "aura", quantity: 1 },
    ],
    cost: 35000,
  },
  {
    title: "Blue ring",
    color: "#bb2b2b",
    miniimg: "ring-images/120px/blue.webp",
    type: "ring",
    needItems: [
      { title: "Small Lucky Potion III", type: "potion", quantity: 5 },
      { title: "Purple-Eye", type: "aura", quantity: 1 },
      { title: "Silver ring", type: "ring", quantity: 1 },
      { title: "Green ring", type: "ring", quantity: 1 },
    ],
    cost: 50000,
  },
  {
    title: "Purple ring",
    color: "#bb2b2b",
    miniimg: "ring-images/120px/purple.webp",
    type: "ring",
    needItems: [
      { title: "Base element", type: "craft", quantity: 3 },
      { title: "Tourmaline", type: "aura", quantity: 3 },
      { title: "Saitamo", type: "aura", quantity: 2 },
      { title: "Sugar", type: "aura", quantity: 2 },
      { title: "DEAD-STAR", type: "aura", quantity: 1 },
      { title: "T0XIC", type: "aura", quantity: 1 },
      { title: "UNKN0WN-F0G", type: "aura", quantity: 1 },
      { title: "Blue ring", type: "ring", quantity: 1 },
    ],
    cost: 100000,
  },
  {
    title: "Orange ring",
    color: "#bb2b2b",
    miniimg: "ring-images/120px/orange.webp",
    type: "ring",
    needItems: [
      { title: "Base element", type: "craft", quantity: 25 },
      { title: "Diamond", type: "aura", quantity: 1 },
      { title: "NEFT", type: "aura", quantity: 100 },
      { title: "Wine", type: "aura", quantity: 15 },
      { title: "Sugar", type: "aura", quantity: 62 },
      { title: "Excalibur", type: "aura", quantity: 3 },
      { title: "Liq-Of-Life", type: "aura", quantity: 80 },
      { title: "Eye", type: "aura", quantity: 15 },
      { title: "Purple ring", type: "ring", quantity: 1 },
    ],
    cost: 500000,
  },
  {
    title: "Rainbow ring",
    color: "#bb2b2b",
    miniimg: "ring-images/120px/rainbow.webp",
    type: "ring",
    needItems: [
      { title: "Base element", type: "craft", quantity: 6 },
      { title: "antigravity", type: "aura", quantity: 1 },
      { title: "UF0", type: "aura", quantity: 10 },
      { title: "Miracle", type: "aura", quantity: 140 },
      { title: "Yellow", type: "aura", quantity: 1000 },
      { title: "DEAD-STAR", type: "aura", quantity: 55 },
      { title: "Orange ring", type: "ring", quantity: 1 },
      { title: "Purple ring", type: "ring", quantity: 1 },
    ],
    cost: 1000000,
  },
];
export default forgeItems;
