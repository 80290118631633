import "./App.css";
import { motion } from "framer-motion";

const SpinButton = (props) => {
  const fillColorStyle = {
    position: "absolute",
    top: "-8px",
    left: 0,
    width: "100%",
    zIndex: -1,
    height: "16px",
    borderRadius: "15px",
    backgroundColor: "rgba(0, 0, 0,0.2)",
    transform: `translateX(${
      (props.clickcount / props.spinscountuntillucky) * 100 - 100
    }%)`,
    transition: "transform 0.2s ease-out",
  };
  return (
    <motion.button
      disabled={props.disabled}
      whileTap={{
        scale: 0.97,
      }}
      transition={{ duration: 0.1 }}
      {...props}
    >
      <div style={fillColorStyle} />
      {props.children}
    </motion.button>
  );
};
export default SpinButton;
