const itemsData = [
  {
    title: "Base element",
    color: "#242424",
    img: "item-images/base.webp",
    miniimg: "item-images/120px/base.webp",
    type: "craft",
  },
  {
    title: "spins",
    color: "#242424",
    miniimg: "item-images/120px/spin-icon.webp",
    type: "stats",
  },
  {
    title: "bestCombo",
    color: "#242424",
    miniimg: "item-images/120px/combo-icon.webp",
    type: "stats",
  },
  {
    title: "Lucky potion",
    color: "#007866",
    img: "potion-images/LuckyPotion.webp",
    miniimg: "potion-images/120px/LuckyPotion.webp",
    multiplyer: 10,
    timer: 1,
    type: "potion",
  },

  {
    title: "Super Lucky potion",
    color: "#c95694",
    img: "potion-images/SuperLuckyPotion.webp",
    miniimg: "potion-images/120px/SuperLuckyPotion.webp",
    multiplyer: 40,
    timer: 0.5,
    type: "potion",
  },
  {
    title: "Small Lucky Potion",
    color: "#3e7f33",
    img: "potion-images/luck1x1.webp",
    miniimg: "potion-images/120px/luck1x1.webp",
    multiplyer: 10,
    timer: 1,
    type: "potion",
  },
  {
    title: "Small Lucky Potion II",
    color: "#3e7f33",
    img: "potion-images/luck1x2.webp",
    miniimg: "potion-images/120px/luck1x2.webp",
    multiplyer: 25,
    timer: 1,
    type: "potion",
  },
  {
    title: "Small Lucky Potion III",
    color: "#3e7f33",
    img: "potion-images/luck1x3.webp",
    miniimg: "potion-images/120px/luck1x3.webp",
    multiplyer: 50,
    timer: 1,
    type: "potion",
  },
  {
    title: "Medium Lucky Potion",
    color: "#3e7f33",
    img: "potion-images/luck2x1.webp",
    miniimg: "potion-images/120px/luck2x1.webp",
    multiplyer: 10,
    timer: 5,
    type: "potion",
  },
  {
    title: "Medium Lucky Potion II",
    color: "#3e7f33",
    img: "potion-images/luck2x2.webp",
    miniimg: "potion-images/120px/luck2x2.webp",
    multiplyer: 25,
    timer: 5,
    type: "potion",
  },
  {
    title: "Medium Lucky Potion III",
    color: "#3e7f33",
    img: "potion-images/luck2x3.webp",
    miniimg: "potion-images/120px/luck2x3.webp",
    multiplyer: 50,
    timer: 5,
    type: "potion",
  },
  {
    title: "Large Lucky Potion",
    color: "#3e7f33",
    img: "potion-images/luck3x1.webp",
    miniimg: "potion-images/120px/luck3x1.webp",
    multiplyer: 10,
    timer: 10,
    type: "potion",
  },
  {
    title: "Large Lucky Potion II",
    color: "#3e7f33",
    img: "potion-images/luck3x2.webp",
    miniimg: "potion-images/120px/luck3x2.webp",
    multiplyer: 25,
    timer: 10,
    type: "potion",
  },
  {
    title: "Large Lucky Potion III",
    color: "#3e7f33",
    img: "potion-images/luck3x3.webp",
    miniimg: "potion-images/120px/luck3x3.webp",
    multiplyer: 50,
    timer: 10,
    type: "potion",
  },

  {
    title: "Small Speed Potion",
    color: "#1a68c8",
    img: "potion-images/speed1x1.webp",
    miniimg: "potion-images/120px/speed1x1.webp",
    speedBonus: 1500,
    timer: 1,
    type: "potion",
  },
  {
    title: "Small Speed Potion II",
    color: "#1a68c8",
    img: "potion-images/speed1x2.webp",
    miniimg: "potion-images/120px/speed1x2.webp",
    speedBonus: 2500,
    timer: 1,
    type: "potion",
  },
  {
    title: "Small Speed Potion III",
    color: "#1a68c8",
    img: "potion-images/speed1x3.webp",
    miniimg: "potion-images/120px/speed1x3.webp",
    speedBonus: 3500,
    timer: 1,
    type: "potion",
  },
  {
    title: "Medium Speed Potion",
    color: "#1a68c8",
    img: "potion-images/speed2x1.webp",
    miniimg: "potion-images/120px/speed2x1.webp",
    speedBonus: 1500,
    timer: 5,
    type: "potion",
  },
  {
    title: "Medium Speed Potion II",
    color: "#1a68c8",
    img: "potion-images/speed2x2.webp",
    miniimg: "potion-images/120px/speed2x2.webp",
    speedBonus: 2500,
    timer: 5,
    type: "potion",
  },
  {
    title: "Medium Speed Potion III",
    color: "#1a68c8",
    img: "potion-images/speed2x3.webp",
    miniimg: "potion-images/120px/speed2x3.webp",
    speedBonus: 3500,
    timer: 5,
    type: "potion",
  },
  {
    title: "Large Speed Potion",
    color: "#1a68c8",
    img: "potion-images/speed3x1.webp",
    miniimg: "potion-images/120px/speed3x1.webp",
    speedBonus: 1500,
    timer: 10,
    type: "potion",
  },
  {
    title: "Large Speed Potion II",
    color: "#1a68c8",
    img: "potion-images/speed3x2.webp",
    miniimg: "potion-images/120px/speed3x2.webp",
    speedBonus: 2500,
    timer: 10,
    type: "potion",
  },
  {
    title: "Large Speed Potion III",
    color: "#1a68c8",
    img: "potion-images/speed3x3.webp",
    miniimg: "potion-images/120px/speed3x3.webp",
    speedBonus: 3500,
    timer: 10,
    type: "potion",
  },

  {
    title: "Silver ring",
    color: "#243e25",
    img: "ring-images/silver.webp",
    miniimg: "ring-images/120px/silver.webp",
    type: "ring",
    luckBonus: 5,
    speedBonus: 0,
  },
  {
    title: "Green ring",
    color: "#04a66b",
    img: "ring-images/green.webp",
    miniimg: "ring-images/120px/green.webp",
    type: "ring",
    luckBonus: 10,
    speedBonus: 1000,
  },
  {
    title: "Blue ring",
    color: "#2868a6",
    img: "ring-images/blue.webp",
    miniimg: "ring-images/120px/blue.webp",
    type: "ring",
    luckBonus: 20,
    speedBonus: 1500,
  },
  {
    title: "Purple ring",
    color: "#6e5788",
    img: "ring-images/purple.webp",
    miniimg: "ring-images/120px/purple.webp",
    type: "ring",
    luckBonus: 30,
    speedBonus: 2000,
  },
  {
    title: "Orange ring",
    color: "#e36f3c",
    img: "ring-images/orange.webp",
    miniimg: "ring-images/120px/orange.webp",
    type: "ring",
    luckBonus: 40,
    speedBonus: 2500,
  },
  {
    title: "Rainbow ring",
    color: "#181f2a",
    img: "ring-images/rainbow.webp",
    miniimg: "ring-images/120px/rainbow.webp",
    type: "ring",
    luckBonus: 50,
    speedBonus: 3000,
  },
  {
    title: "Leather glove",
    color: "#e0cfb0",
    img: "glove-images/leather-glove.webp",
    miniimg: "glove-images/120px/leather-glove.webp",
    type: "glove",
    spinsCount: 8,
    bonusSpinLuck: 10,
  },
  {
    title: "Lucky glove",
    description: "Random Super Luck Potions may appear",
    color: "#1b4b2b",
    img: "glove-images/lucky-glove.webp",
    miniimg: "glove-images/120px/lucky-glove.webp",
    type: "glove",
  },
  {
    title: "Metal glove",
    color: "#284854",
    img: "glove-images/metal-glove.webp",
    miniimg: "glove-images/120px/metal-glove.webp",
    type: "glove",
    spinsCount: 6,
    bonusSpinLuck: 15,
  },
  {
    title: "Metal magic glove",
    color: "#3dacc9",
    img: "glove-images/metal-magic-glove.webp",
    miniimg: "glove-images/120px/metal-magic-glove.webp",
    type: "glove",
    spinsCount: 15,
    bonusSpinLuck: 30,
  },

  {
    title: "Magic glove",
    color: "#121b1f",
    img: "glove-images/magic-glove.webp",
    miniimg: "glove-images/120px/magic-glove.webp",
    type: "glove",
    spinsCount: 10,
    bonusSpinLuck: 35,
  },
  {
    title: "Mecha glove",
    color: "#e0cfb0",
    img: "glove-images/mecha-glove.webp",
    miniimg: "glove-images/120px/mecha-glove.webp",
    type: "glove",
    spinsCount: 6,
    bonusSpinLuck: 35,
  },
  {
    title: "Rainbow glove",
    color: "#0a1620",
    img: "glove-images/rainbow-glove.webp",
    miniimg: "glove-images/120px/rainbow-glove.webp",
    type: "glove",
    spinsCount: 6,
    bonusSpinLuck: 40,
  },
];
export default itemsData;
