const ArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <path
      d="M8.2,14.7L19.3,3.6c0.8-0.8,2.2-0.8,3.1,0l0,0c0.8,0.8,0.8,2.2,0,3.1L11.3,17.8c-0.8,0.8-2.2,0.8-3.1,0l0,0
	C7.4,16.9,7.4,15.6,8.2,14.7z"
    />
    <path
      d="M11.3,14.7l11.1,11.1c0.8,0.8,0.8,2.2,0,3.1l0,0c-0.8,0.8-2.2,0.8-3.1,0L8.2,17.8c-0.8-0.8-0.8-2.2,0-3.1l0,0
	C9,13.9,10.4,13.9,11.3,14.7z"
    />
  </svg>
);

export default ArrowLeft;
