import { Howl } from "howler";

export const spinSound = new Howl({
  src: ["/sounds/spin.ogg"],
  loop: true,
});

export const s_buttonClick = new Howl({
  src: ["/sounds/buttonClick.mp3"],
});

export const s_gameBg = new Howl({
  src: ["/sounds/gameBg2.mp3"],
  loop: true,
});

export const s_charging = new Howl({
  src: ["/sounds/charging.mp3"],
});

export const s_gameSwitch = new Howl({
  src: ["/sounds/gameSwitch.mp3"],
});

export const s_newAura = new Howl({
  src: ["/sounds/newAura.ogg"],
});
export const s_newAura2 = new Howl({
  src: ["/sounds/newAura2.ogg"],
});

export const s_takeAura = new Howl({
  src: ["/sounds/takeAura.ogg"],
});

export const s_auraMenu = new Howl({
  src: ["/sounds/auraMenu.mp3"],
});

export const s_useItem = new Howl({
  src: ["/sounds/useItem.mp3"],
});

export const s_mainAura = new Howl({
  src: ["/sounds/mainAuraUpdate.ogg"],
});

export const s_spin = new Howl({
  src: ["/sounds/spin.mp3"],
});

export const s_spin2 = new Howl({
  src: ["/sounds/spin2.mp3"],
});

export const s_spinError = new Howl({
  src: ["/sounds/spinError.mp3"],
});

export const s_tapGamePoint = new Howl({
  src: ["/sounds/tapGamePoint.ogg"],
});

export const s_tapGameLoose = new Howl({
  src: ["/sounds/tapGameLoose.ogg"],
});

export const s_tapGameCoins = new Howl({
  src: ["/sounds/tapGameCoins.ogg"],
});

export const s_auraBg = new Howl({
  src: ["/sounds/auraBg.ogg"],
  loop: true,
  volume: 0.3,
});

export const s_shopBg = new Howl({
  src: ["/sounds/shopBg.ogg"],
  loop: true,
});

export const s_nope = new Howl({
  src: ["/sounds/nope.ogg"],
});

export const s_click01 = new Howl({
  src: ["/sounds/click01.wav"],
});

export const s_coins = new Howl({
  src: ["/sounds/coins.ogg"],
});

export const s_joeBg = new Howl({
  src: ["/sounds/joeBg.ogg"],
  loop: true,
  volume: 0.2,
});

export const s_joeLoose = new Howl({
  src: ["/sounds/joeLoose.ogg"],
});

export const s_joeWin = new Howl({
  src: ["/sounds/joeWin.ogg"],
});

export const s_joeWin2 = new Howl({
  src: ["/sounds/joeWin2.ogg"],
});

export const s_slotEnd = new Howl({
  src: ["/sounds/slotEnd.ogg"],
});

export const s_slotsStart = new Howl({
  src: ["/sounds/slotsStart.mp3"],
});

export const s_slotsButton = new Howl({
  src: ["/sounds/slotsButton.mp3"],
});

export const s_joeSpinning = new Howl({
  src: ["/sounds/joeSpinning.ogg"],
});

export const s_joeError = new Howl({
  src: ["/sounds/joeError.mp3"],
});

export const s_forging = new Howl({
  src: ["/sounds/forging.ogg"],
});

export const s_sewing = new Howl({
  src: ["/sounds/sewing.ogg"],
});
export const s_craftPotion = new Howl({
  src: ["/sounds/craftPotion.mp3"],
});

export const s_usePotion = new Howl({
  src: ["/sounds/usePotion.mp3"],
});

export const s_blacksmith = new Howl({
  src: ["/sounds/blacksmith.ogg"],
  loop: true,
});

export const s_tailor = new Howl({
  src: ["/sounds/tailor.ogg"],
  loop: true,
});

export const s_city = new Howl({
  src: ["/sounds/city.ogg"],
  loop: true,
});

export const s_skeleton = new Howl({
  src: ["/sounds/skeleton.ogg"],
  loop: true,
  volume: 0.65,
});
