import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

const MiniGame = ({ onEndGame, onScore }) => {
  const [isGameActive, setIsGameActive] = useState(true);
  const [growingCircleSize, setGrowingCircleSize] = useState(10);
  const [maxCircleSize, setMaxCircleSize] = useState(100);

  const gameContainerRef = useRef(null); // Ссылка на контейнер игры
  const tolerance = 8;

  const endGame = () => {
    setIsGameActive(false);
    time = 0;
    onEndGame();
  };

  const handleTap = () => {
    if (
      growingCircleSize >= maxCircleSize - tolerance &&
      growingCircleSize <= maxCircleSize + tolerance
    ) {
      onScore(); // Вызываем функцию начисления farm

      setGrowingCircleSize(10);
      setMaxCircleSize(80 + Math.random() * 60);
    } else {
      endGame();
    }
  };
  let time = 0.8;

  useEffect(() => {
    let interval;
    if (isGameActive) {
      interval = setInterval(() => {
        setGrowingCircleSize((size) => {
          time += 0.01;
          if (size < maxCircleSize + 50) return size + time;
          else {
            endGame();
            return size;
          }
        });
      }, 15);
    }
    return () => clearInterval(interval);
  }, [isGameActive, maxCircleSize]);

  return (
    <motion.div
      onTap={handleTap}
      ref={gameContainerRef}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "100%",
        height: "100%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
      }}
    >
      <motion.div
        className="big-circle"
        style={{
          transition: "0.3s ease",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: maxCircleSize * 2,
          height: maxCircleSize * 2,
          borderRadius: "50%",
          border: `3px solid ${
            growingCircleSize >= maxCircleSize - tolerance &&
            growingCircleSize <= maxCircleSize + tolerance
              ? "#b0f321"
              : "white"
          }`,
          position: "absolute",
        }}
      ></motion.div>
      <motion.div
        className="growing-circle"
        style={{
          opacity: 0,
          width: growingCircleSize * 2,
          height: growingCircleSize * 2,
          borderRadius: "50%",
          border: `3px solid ${
            growingCircleSize >= maxCircleSize - tolerance &&
            growingCircleSize <= maxCircleSize + tolerance
              ? "#b0f321"
              : "white"
          }`,
          borderWidth:
            growingCircleSize >= maxCircleSize - tolerance &&
            growingCircleSize <= maxCircleSize + tolerance
              ? "6px"
              : "3px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        animate={{ opacity: 1 }}
        transition={{ type: "spring", stiffness: 100 }}
      />
    </motion.div>
  );
};

export default MiniGame;
