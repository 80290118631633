import BlacksmithActive from "./BlacksmithActive";
import TakeIcon from "./Icons/TakeIcon";
import { s_blacksmith, s_buttonClick, s_city } from "./Sounds.js";

const Blacksmith = ({
  t,
  motion,
  forgeItems,
  setActiveCraftItem,
  setActiveCraftItemInfo,
  localProfile,
  itemInfoList,
  handleCraft,
  activeCraftItem,
  hasAllRequiredItems,
  shopItems,
  activeCraftItemInfo,
  hasRequiredItem,
  setNowOpen,
  craftingAnimation,
}) => {
  return (
    <>
      <div className="inventory-title">
        <div>{t("GatanoaPlace")}</div>
        <TakeIcon
          onClick={() => {
            setNowOpen("city");
            s_blacksmith.stop();
            s_city.play();
          }}
          className="back-icon"
          fill="#e0e0e0dc"
          size="20px"
        ></TakeIcon>
      </div>
      <motion.div
        initial={{ x: 30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        className="inventory-container add-ui-container"
        // style={{ backgroundImage: "url(background-01.webp)" }}
      >
        <div>
          <div className="blacksmith-items-wrapper">
            {forgeItems.map((item, index) => (
              <div
                // style={{ backgroundColor: item.color }}
                key={index}
                onClick={() => {
                  setActiveCraftItem(item);
                  s_buttonClick.play();
                }}
                className="blacksmith-item"
              >
                <div
                  style={{
                    backgroundColor: hasAllRequiredItems(item.needItems)
                      ? "yellow"
                      : "red",
                  }}
                  className="blacksmith-item-circle"
                ></div>
                <img
                  className="blacksmith-item-img"
                  src={item.miniimg}
                  alt="miniaura"
                ></img>

                <div className="blacksmith-item-title">{t(item.title)}</div>
              </div>
            ))}
          </div>
        </div>

        {shopItems.length === 0 && (
          <div style={{ textAlign: "center", padding: "30px 10px" }}>
            {t("empty")}
          </div>
        )}
      </motion.div>
      {activeCraftItem != null && (
        <BlacksmithActive
          {...{
            activeCraftItem,
            activeCraftItemInfo,
            setActiveCraftItem,
            setActiveCraftItemInfo,
            motion,
            t,
            hasRequiredItem,
            localProfile,
            itemInfoList,
            handleCraft,
            craftingAnimation,
          }}
        ></BlacksmithActive>
      )}
    </>
  );
};
export default Blacksmith;
