import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { getItemByName } from "./Functions";
const InventoryItem = ({ t, item, onClick, opacity }) => {
  const [itemDetails, setItemDetails] = useState(null);

  useEffect(() => {
    const fetchItemDetails = async () => {
      const details = await getItemByName(item.title);
      setItemDetails(details);
    };
    fetchItemDetails();
  }, [item.title]);

  if (!itemDetails) return null;

  return (
    <motion.div
      onClick={onClick}
      style={{ backgroundColor: itemDetails.color, opacity: opacity }}
      className="table-item"
    >
      <div className="table-item-name">{t(item.title)}</div>
      <img
        className="table-item-img"
        width={"100%"}
        src={itemDetails.miniimg}
        alt={`${item.title} image`}
      />
      <div className="table-item-quantity">{item.quantity}</div>
    </motion.div>
  );
};
export default InventoryItem;
