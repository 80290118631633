const CityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <path
      d="M30.2,8.8l-4.1-5.6C26,3,25.7,3,25.5,3.2l-4.3,5c-0.1,0.2-0.1,0.4,0,0.5l0.5,0.4c0.1,0.1,0.1,0.2,0.1,0.3l0,1.8
	c0,0.3-0.3,0.4-0.5,0.3l-3.5-1.8c-0.1,0-0.1-0.1-0.2-0.1l-2.4-4.4c-0.1-0.2-0.4-0.3-0.6-0.1L13,6.9C12.9,7,12.7,7.1,12.6,7l-6-3.5
	c-0.2-0.1-0.4,0-0.5,0.1L3.4,8.6c0,0,0,0,0,0.1l-1.7,1.9c-0.2,0.2-0.1,0.5,0.1,0.6l0.7,0.4l8.3,4L3,12.3c-0.2-0.1-0.5,0.1-0.5,0.3
	l-0.4,8.1c0,0.1,0.1,0.3,0.2,0.3l7.3,4c0.1,0.1,0.2,0.2,0.2,0.4l-0.2,1c0,0.1,0,0.3,0.2,0.4l4.5,2.8c0.1,0.1,0.2,0.1,0.3,0l6.4-2.7
	c0.1-0.1,0.2-0.2,0.2-0.3V22c0-1.9,2.1-2.6,2.1-0.3c0,1.2,0,2.7,0,3.8c0,0.3,0.3,0.4,0.5,0.3l6.4-2.6c0.1-0.1,0.2-0.2,0.2-0.4
	l-0.6-13c0-0.1,0-0.2,0.1-0.3l0.2-0.2C30.3,9.1,30.3,8.9,30.2,8.8z M4.2,18.2C4.1,18.1,4,18,4,17.9v-2.1c0-0.8,1.4-1,1.3,0
	c0,0.6,0,1.8,0,2.5c0,0.3-0.3,0.5-0.5,0.3L4.2,18.2z M8,20.1l-0.6-0.3c-0.1-0.1-0.2-0.2-0.2-0.3v-2.1c0-0.8,1.4-1,1.3,0
	c0,0.6,0,1.8,0,2.5C8.5,20.1,8.2,20.3,8,20.1z M17.4,18.3l-0.6,0.4c-0.2,0.1-0.5,0-0.5-0.3c0-0.7-0.1-1.9,0-2.5
	c0.2-1.1,1.3-0.8,1.3,0V18C17.6,18.1,17.5,18.2,17.4,18.3z M22.7,18.1c-0.4,0-0.7-0.8-0.7-1.8c0-1,0.3-1.8,0.7-1.8s0.7,0.8,0.7,1.8
	C23.5,17.3,23.1,18.1,22.7,18.1z M28.4,13.6l-0.6,0.4c-0.2,0.1-0.5,0-0.5-0.3c0-0.7,0-1.8,0-2.5c0.1-1,1.3-0.8,1.3,0v2.1
	C28.6,13.4,28.5,13.5,28.4,13.6z"
    />
  </svg>
);

export default CityIcon;
