const ForgeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <path
      d="M30.7,12.3l-1.6-1.4c-0.3-0.2-0.6-0.2-0.8,0c-1-1.5-2.3-3.1-4-4.4l0.1-0.1c-0.1-0.1,0-0.5-0.3-0.7l-1.6-1.4
	c-0.3-0.2-0.6-0.2-0.8,0L21.5,5c-0.4-0.1-0.8-0.3-1.1-0.4c-0.6,0.8-2.9,3.4-3.6,4.1c-0.5,0.4-0.3,0.4,0,0.8c0.1,0.1,0.3,0.3,0.4,0.5
	l-0.4,0.5c-0.2,0.2-0.2,0.7,0.1,0.9l1.6,1.4c0.3,0.2,0.6,0.2,0.8,0l0.1-0.1c0.2,0.1,0.5,0.4,0.8,0.6c0.1,0.1-0.1,0.2,0,0.3
	c-1.3,1.5-2.7,3-4,4.5c-1.4-1.5-2.7-3-4-4.5c0.1-0.1-0.1-0.2,0-0.3c0.2-0.2,0.6-0.5,0.8-0.6l0.1,0.1c0.2,0.3,0.5,0.2,0.8,0l1.6-1.4
	c0.3-0.2,0.3-0.7,0.1-0.9l-0.4-0.5c0.1-0.1,0.3-0.3,0.4-0.5c0.3-0.3,0.5-0.4,0-0.8c-0.6-0.8-2.9-3.4-3.6-4.1
	c-0.4,0.1-0.8,0.3-1.1,0.4l-0.4-0.5c-0.2-0.3-0.5-0.2-0.8,0L7.8,5.8C7.5,6,7.5,6.4,7.5,6.5l0.1,0.1c-1.7,1.3-3,2.9-4,4.4
	c-0.2-0.3-0.5-0.2-0.8,0l-1.6,1.4c-0.3,0.2-0.2,0.5,0,0.8l0.6,0.8c-0.1,0.1-0.3,0.3-0.4,0.5c-0.1,0.1-0.3,0.2,0,0.5
	c1.2,1.3,2.2,2.6,3.3,3.7c0.1,0.2,0.2,0.3,0.5-0.1c0.1-0.1,0.2-0.1,0.3-0.2L6,18.7c0.2,0.3,0.5,0.2,0.8,0l1.6-1.4
	c0.3-0.2,0.2-0.5,0-0.8l-0.2-0.2c0.4-0.3,0.9-0.6,1.5-0.8c1.4,1.6,2.9,3.2,4.3,4.8c-1.3,1.4-2.6,2.9-3.9,4.3
	c-0.5,0.4-0.6,1.1-0.4,1.6l0.4,0.9c0.2,0.5,0.9,0.7,1.4,0.3c1.5-1.6,3-3.3,4.5-4.9c1.5,1.6,3,3.3,4.5,4.9c0.5,0.4,1.2,0.2,1.4-0.3
	l0.4-0.9c0.2-0.5,0.1-1.2-0.4-1.6c-1.3-1.4-2.6-2.9-3.9-4.3c1.4-1.6,2.9-3.2,4.3-4.8c0.6,0.2,1,0.5,1.5,0.8l-0.2,0.2
	c-0.2,0.3-0.2,0.6,0,0.8l1.6,1.4c0.3,0.2,0.6,0.2,0.8,0l0.5-0.6c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.3,0.4,0.3,0.5,0.1
	c1.1-1.1,2.1-2.4,3.3-3.7c0.2-0.3,0-0.3,0-0.5c-0.1-0.1-0.3-0.3-0.4-0.5l0.6-0.8C31,12.8,31,12.5,30.7,12.3z"
    />
  </svg>
);

export default ForgeIcon;
