import { useState } from "react";

const ActiveItem = ({
  activeItem,
  setActiveItem,
  motion,
  handleItemRemove,
  handleEquipmentUpdate,
  changeMainAura,
  handleSell,
  t,
}) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const imgLoaded = () => {
    setIsImgLoaded(true);
  };

  return (
    <>
      <motion.div
        initial={{ scale: 0.7 }}
        animate={{ scale: 1 }}
        className="zoom-item-wrapper"
      >
        <div
          className="zoom-item-container"
          style={{
            backgroundColor:
              activeItem.type === "aura" ? "black" : activeItem.color,
            backgroundImage:
              activeItem.type === "potion" ? "url(potions-texture.webp)" : "",
          }}
        >
          <div
            style={
              activeItem.type !== "aura"
                ? { color: "#dfdfdfd5", backgroundColor: "#0000003a" }
                : { color: "black", backgroundColor: activeItem.color }
            }
            className="current-name-container"
          >
            <div className="zoom-item-title">{t(activeItem.title)}</div>
            {activeItem.type === "aura" && (
              <div
                className="current-rarity"
                style={{ color: activeItem.color }}
              >
                {"1/" + activeItem.probability}
              </div>
            )}
          </div>
          {activeItem.quantity && (
            <div className="zoom-item-quantity">
              {activeItem.quantity} {t("pcs.")}
            </div>
          )}

          {/* Заглушка для изображения */}
          <div
            className="shimmer"
            style={{
              display: isImgLoaded ? "none" : "block",
            }}
          ></div>

          {/* Основное изображение */}
          {activeItem?.webm && (
            <>
              <video
                className="zoom-item-img"
                src={activeItem.img}
                onLoadedData={imgLoaded}
                autoPlay
                loop
                muted
                style={{ display: isImgLoaded ? "block" : "none" }}
              />
            </>
          )}
          {!activeItem?.webm && (
            <img
              className="zoom-item-img"
              src={activeItem.img}
              onLoad={imgLoaded}
              style={{ display: isImgLoaded ? "block" : "none" }}
              alt="active-item"
            />
          )}

          {activeItem.type !== "aura" && (
            <div
              style={activeItem.type === "aura" ? { color: "white" } : null}
              className="zoom-item-description"
            >
              {activeItem.type === "ring" && (
                <>
                  {activeItem.luckBonus && (
                    <div>
                      {t("base luck")} +{activeItem.luckBonus}
                    </div>
                  )}
                  {activeItem.type === "ring" && activeItem.speedBonus > 0 && (
                    <div>
                      {t("spin time")} -{activeItem.speedBonus / 1000}{" "}
                      {t("sec")}
                    </div>
                  )}
                </>
              )}
              {activeItem.type === "potion" && (
                <>
                  {activeItem.multiplyer > 0 && (
                    <div>
                      {t("luck")} +{activeItem.multiplyer} <br />
                      {t("duration")} {activeItem.timer} {t("min")}
                    </div>
                  )}
                  {activeItem.speedBonus > 0 && (
                    <div>
                      {t("spin time")} -{activeItem.speedBonus / 1000}{" "}
                      {t("sec")} <br />
                      {t("duration")} {activeItem.timer} {t("min")}
                    </div>
                  )}
                </>
              )}
              {activeItem.type === "craft" && (
                <div>{t("need for crafting")}</div>
              )}
              {activeItem.type === "glove" && (
                <>
                  {activeItem.description && (
                    <span>{t(activeItem.description)}</span>
                  )}
                  {!activeItem.description && (
                    <span>
                      {t("bonus luck")} {activeItem?.bonusSpinLuck} {t("every")}{" "}
                      {activeItem?.spinsCount} {t("spins")}
                      <br></br>
                    </span>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {activeItem.type === "ring" && !activeItem.equipped && (
          <button
            className="zoom-item-button"
            onClick={() => handleEquipmentUpdate(activeItem, "equip")}
          >
            {t("put l")}
          </button>
        )}
        {activeItem.type === "ring" && activeItem.equipped && (
          <button
            className="zoom-item-button"
            onClick={() => handleEquipmentUpdate(activeItem, "unequip")}
          >
            {t("take off")}
          </button>
        )}
        {activeItem.type === "potion" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <button
              className="zoom-item-button"
              onClick={() => handleItemRemove(activeItem, 1)}
            >
              {t("use")}
            </button>
            <button
              className="zoom-item-button"
              onClick={() => handleItemRemove(activeItem, activeItem.quantity)}
            >
              {t("use all")}
            </button>
          </div>
        )}
        {activeItem.type === "glove" && !activeItem.equipped && (
          <button
            className="zoom-item-button"
            onClick={() => handleEquipmentUpdate(activeItem, "equip")}
          >
            {t("put r")}
          </button>
        )}
        {activeItem.type === "glove" && activeItem.equipped && (
          <button
            className="zoom-item-button"
            onClick={() => handleEquipmentUpdate(activeItem, "unequip")}
          >
            {t("take off")}
          </button>
        )}
        {activeItem.type === "aura" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <button
              className="zoom-item-button"
              onClick={() => changeMainAura(activeItem)}
            >
              {t("main")}
            </button>
            <button
              className="zoom-item-button"
              onClick={() => handleSell(activeItem, 1)}
            >
              {t("sell one")}
            </button>
            <button
              className="zoom-item-button"
              onClick={() => handleSell(activeItem, activeItem.quantity - 1)}
            >
              {t("sell all")}
            </button>
          </div>
        )}
      </motion.div>
      <div
        onClick={() => {
          setActiveItem(null);
        }}
        className="black-screen"
        style={{ backgroundColor: "#000000c4", zIndex: 4 }}
      ></div>
    </>
  );
};

export default ActiveItem;
