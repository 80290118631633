const AlchemistIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M20,3.1c-0.3,0.4-0.6,0.7-0.8,1.1C18.8,4,18.4,3.9,18,3.8c-0.3-0.1-0.4,0-0.3,0.3c0.2,0.5,0.4,1,0.6,1.5
		C18.5,6,18.8,6.4,19,6.8c0.8-1,1.6-2,2.3-3c1.5,3.3,3.9,5.6,7.1,7.2c-1,0.7-2,1.5-3,2.2c0.3,0.2,0.5,0.3,0.7,0.5
		c0.6,0.3,1.2,0.7,1.9,0.8c0,0,0.1,0,0.1,0c0.3,0,0.3,0,0.3-0.3c-0.1-0.4-0.2-0.8-0.4-1.2c0.3-0.2,1.1-0.8,1.1-0.8s0.2,0.5,0.3,0.7
		c0.3,0.6,0.4,1.2,0.3,1.9c-0.1,0.3-0.2,0.6-0.5,0.8c-0.6,0.6-1.2,1.2-1.9,1.9c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,3.2-1.1,6-3.2,8.4
		c-1.4,1.5-3.1,2.7-5,3.4c-1.5,0.6-3,0.8-4.6,0.8c-1.9,0-3.8-0.5-5.5-1.4c-2.9-1.5-5-3.8-6.1-6.9c-0.6-1.5-0.8-3.1-0.8-4.7
		c0.1-2.5,0.7-4.8,2.2-6.8C6,7.7,8.7,5.9,12,5.2C12.8,5.1,13.6,5,14.4,5c0.1,0,0.2,0,0.2-0.1c0.6-0.6,1.2-1.2,1.8-1.8
		c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.3,0.8-0.3,1.3-0.2C18.8,2.5,19.4,2.8,20,3.1z M13.6,6.4C8.4,6.7,3,11.4,3.3,18.1
		c0.2,6,5.4,11.2,12,10.8c6.1-0.4,10.3-5.3,10.6-10.3c-0.3,0-0.7-0.1-1-0.1c-1-0.3-1.9-0.8-2.8-1.4c-1.4-1-2.8-2.1-4-3.4
		c-1.4-1.4-2.6-2.8-3.6-4.5C14,8.2,13.6,7.4,13.6,6.4z M24.9,14.5c-1.6-1.1-3.1-2.3-4.4-3.7c-0.9-0.9-1.8-1.9-2.5-3
		c-0.6-0.8-1.2-1.7-1.5-2.7c0,0-0.1,0-0.1-0.1c-0.4,0.4-0.8,0.9-1.2,1.3C15,6.4,15,6.5,15,6.7c0.1,0.3,0.1,0.5,0.2,0.8
		c0.4,1,1,1.8,1.6,2.7c1.2,1.5,2.5,2.9,3.9,4.1c1,0.9,2.1,1.7,3.3,2.4c0.4,0.2,0.9,0.4,1.4,0.5c0.1,0,0.2,0,0.4-0.1
		c0.4-0.4,0.8-0.8,1.2-1.2c0,0,0.1-0.1,0.1-0.1"
      />
      <path
        d="M23.4,1.7c0.4,0,0.9,0.3,1.5,0.6c1.2,0.7,2.3,1.6,3.2,2.6c0.8,0.8,1.5,1.7,2,2.7c0.2,0.4,0.4,0.8,0.4,1.3
		c0.1,0.6-0.3,1-0.9,0.9c-0.2,0-0.3-0.1-0.5-0.1c-0.7-0.2-1.4-0.6-2-1.1c-1.5-1.1-2.9-2.5-3.9-4.1c-0.3-0.5-0.6-1.1-0.7-1.7
		c0-0.2,0-0.3,0-0.5C22.5,1.9,22.8,1.7,23.4,1.7z"
      />
      <path
        d="M7.6,13.6c0,0.9,0,1.6,0.2,2.4c0.5,1.7,1.7,2.4,3.3,2.4c0.4,0,0.8-0.1,1.2-0.3c0.7-0.3,1.4-0.5,2.1-0.7
		c1-0.3,2-0.4,2.9-0.4c1.8,0.1,3.5,0.7,4.6,2.2c0.7,0.9,1,2,0.7,3.2c-0.1,0.5-0.4,0.9-0.8,1.3c-1.6,2-3.7,3.2-6.2,3.7
		c-4,0.6-8.1-1.4-10-5C5,20.7,4.7,19,4.8,17.1c0.1-1.7,0.6-3.2,1.5-4.7c0.2-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.5,0.4,0.5,0.7
		C7.6,13.1,7.6,13.4,7.6,13.6z"
      />
    </g>
  </svg>
);

export default AlchemistIcon;
