import React, { useState, useEffect } from "react";
import "./LuckAnimation.css";
import DucksIcon from "./Icons/DucksIcon";

const LuckAnimation = (props) => {
  const [clicks, setClicks] = useState([]);

  useEffect(() => {
    const handleLuckClick = (e) => {
      // const { clientX, clientY } = e.detail.event;
      const newClick = { id: Date.now(), x: props.clientX, y: props.clientY };
      setClicks((prevClicks) => [...prevClicks, newClick]);

      setTimeout(() => {
        setClicks((prevClicks) =>
          prevClicks.filter((click) => click.id !== newClick.id)
        );
      }, 2000);
    };

    document.addEventListener("luck-click", handleLuckClick);

    return () => {
      document.removeEventListener("luck-click", handleLuckClick);
    };
  }, []);

  return (
    <>
      {clicks.map((click) => (
        <div key={click.id} className="luck">
          +{props.count}
          <DucksIcon style={{ marginLeft: "5px" }} size={"20px"}></DucksIcon>
        </div>
      ))}
    </>
  );
};

export default LuckAnimation;
