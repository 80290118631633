const aurasData = [
  {
    title: "Stone",
    color: "#D4C0A8",
    img: "images/Stone.webp",
    miniimg: "images/120px/Stone.webp",
    probability: 4,
  },
  {
    title: "Mossy",
    color: "#6f9a2b",
    img: "images/Rock with moss.webp",
    miniimg: "images/120px/Rock with moss.webp",
    probability: 8,
  },

  {
    title: "Malachite",
    color: "#2fb280",
    img: "images/Malachite.webp",
    miniimg: "images/120px/Malachite.webp",
    probability: 16,
  },

  {
    title: "Grogus",
    color: "#9cb577",
    img: "images/grogu.webp",
    miniimg: "images/120px/grogu.webp",
    probability: 24,
  },

  {
    title: "Ell",
    color: "#babda4",
    img: "images/l.webp",
    miniimg: "images/120px/l.webp",
    probability: 32,
  },

  {
    title: "Steeve",
    color: "#75c2cb",
    img: "images/steeve.webp",
    miniimg: "images/120px/steeve.webp",
    probability: 48,
  },
  {
    title: "Uncommon-GEM",
    color: "#60d749",
    img: "images/Incrusted uncommon.webp",
    miniimg: "images/120px/Incrusted uncommon.webp",
    probability: 60,
  },
  {
    title: "White",
    color: "#ffffff",
    img: "images/White.webp",
    miniimg: "images/120px/White.webp",
    probability: 64,
  },
  {
    title: "Buttrick",
    color: "#94cb2d",
    img: "images/patrick.webp",
    miniimg: "images/120px/patrick.webp",
    probability: 80,
  },
  {
    title: "Rare-GEM",
    color: "#0096ff",
    img: "images/Incrusted rare.webp",
    miniimg: "images/120px/Incrusted rare.webp",
    probability: 100,
  },
  {
    title: "Yellow",
    ruName: "Жёлтая",
    color: "#ffcc00",
    img: "images/Yellow.webp",
    miniimg: "images/120px/Yellow.webp",
    probability: 128,
  },
  {
    title: "Carl Johanson",
    color: "#dbd2cf",
    img: "images/cj.webp",
    miniimg: "images/120px/cj.webp",
    probability: 145,
  },
  {
    title: "Evil",
    color: "#be3d33",
    img: "images/Evil.webp",
    miniimg: "images/120px/Evil.webp",
    probability: 150,
  },
  {
    title: "Azuka",
    color: "#e43e37",
    img: "images/asuka.webp",
    miniimg: "images/120px/asuka.webp",
    probability: 160,
  },
  {
    title: "Demantoid",
    color: "#4BDF5F",
    img: "images/Demantoid.webp",
    miniimg: "images/120px/Demantoid.webp",
    probability: 170,
  },
  {
    title: "Marley Brinn",
    color: "#55719f",
    img: "images/harley.webp",
    miniimg: "images/120px/harley.webp",
    probability: 180,
  },
  {
    title: "Sinosuke",
    color: "#468386",
    img: "images/inosuke.webp",
    miniimg: "images/120px/inosuke.webp",
    probability: 190,
  },
  {
    title: "Dispersion",
    color: "#ffffff",
    img: "images/Rainbow.webp",
    miniimg: "images/120px/Rainbow.webp",
    probability: 200,
  },
  {
    title: "Ciley Myrus",
    color: "#ca4a49",
    img: "images/miley.webp",
    miniimg: "images/120px/miley.webp",
    probability: 220,
  },

  {
    title: "Flower",
    ruName: "Цветочная",
    color: "#f4ebd4",
    img: "images/Daisies.webp",
    miniimg: "images/120px/Daisies.webp",
    probability: 250,
  },
  {
    title: "Watermelon",
    ruName: "Арбуз",
    color: "#fdf421",
    img: "images/Watermelon.webp",
    miniimg: "images/120px/Watermelon.webp",
    probability: 300,
  },
  {
    title: "M-Turtle",
    color: "#fa9825",
    img: "images/michelangelo.webp",
    miniimg: "images/120px/michelangelo.webp",
    probability: 350,
  },
  {
    title: "Ba-Na-Na",
    ruName: "Банан",
    color: "#ffb400",
    img: "images/Banana.webp",
    miniimg: "images/120px/Banana.webp",
    probability: 400,
  },
  {
    title: "Pizza",
    ruName: "Пицца",
    color: "#24a22d",
    img: "images/Pizza.webp",
    miniimg: "images/120px/Pizza.webp",
    probability: 420,
  },
  {
    title: "Marada",
    color: "#924642",
    img: "images/madara.webp",
    miniimg: "images/120px/madara.webp",
    probability: 450,
  },
  {
    title: "Coin",
    ruName: "Монетка",
    color: "#d39e21",
    img: "images/Coin.webp",
    miniimg: "images/120px/Coin.webp",
    probability: 500,
  },

  {
    title: "Tourmaline",
    color: "#2179d3",
    img: "images/Tourmaline.webp",
    miniimg: "images/120px/Tourmaline.webp",
    probability: 550,
  },
  {
    title: "NARZAN",
    ruName: "Нарзан",
    color: "#7dee77",
    img: "images/NARZAN.webp",
    miniimg: "images/120px/NARZAN.webp",
    probability: 600,
  },
  {
    title: "Sonix",
    color: "#3b7ae9",
    img: "images/sonic.webp",
    miniimg: "images/120px/sonic.webp",
    probability: 650,
  },
  {
    title: "Shegger",
    color: "#77a84a",
    img: "images/shaggy.webp",
    miniimg: "images/120px/shaggy.webp",
    probability: 700,
  },
  {
    title: "Lucky",
    ruName: "Счастливая",
    color: "#4e9a3c",
    img: "images/Lucky.webp",
    miniimg: "images/120px/Lucky.webp",
    probability: 777,
  },
  {
    title: "NEFT",
    ruName: "НЕФТЬ",
    color: "#262626",
    img: "images/Darkie.webp",
    miniimg: "images/120px/Darkie.webp",
    probability: 800,
  },
  {
    title: "Previ",
    color: "#706c63",
    img: "images/levi.webp",
    miniimg: "images/120px/levi.webp",
    probability: 850,
  },
  {
    title: "Chromium",
    color: "#6e5d9b",
    img: "images/Chromium.webp",
    miniimg: "images/120px/Chromium.webp",
    probability: 890,
  },
  {
    title: "Miracle",
    ruName: "Диво",
    color: "#1dc6e7",
    img: "images/Miracle.webp",
    miniimg: "images/120px/Miracle.webp",
    probability: 900,
  },
  {
    title: "Hamster",
    ruName: "Хомяк",
    color: "#efb596",
    img: "images/Hamster.webp",
    miniimg: "images/120px/Hamster.webp",
    probability: 950,
  },
  {
    title: "Liq-Of-Life",
    color: "#2770f6",
    img: "images/Liquid of life.webp",
    miniimg: "images/120px/Liquid of life.webp",
    probability: 999,
  },
  {
    title: "Saitamo",
    color: "#f8d86b",
    img: "images/one punch man.webp",
    miniimg: "images/120px/one punch man.webp",
    probability: 1000,
  },

  {
    title: "Smiley—R0CK",
    ruName: "Добрый камень",
    color: "#dfe8e9",
    img: "images/Smiley.webp",
    miniimg: "images/120px/Smiley.webp",
    probability: 1100,
  },
  {
    title: "Kitty woman",
    color: "#68625c",
    img: "images/catwoman.webp",
    miniimg: "images/120px/catwoman.webp",
    probability: 1150,
  },
  {
    title: "Saint",
    ruName: "Святость",
    color: "#fbb91c",
    img: "images/Saint.webp",
    miniimg: "images/120px/Saint.webp",
    probability: 1200,
  },
  {
    title: "J-Woo",
    color: "#e3dccf",
    img: "images/jason voorhes.webp",
    miniimg: "images/120px/jason voorhes.webp",
    probability: 1250,
  },
  {
    title: "Pohacontas",
    color: "#68817b",
    img: "images/pocahontas.webp",
    miniimg: "images/120px/pocahontas.webp",
    probability: 1300,
  },

  {
    title: "Purple hat",
    color: "#7a58b9",
    img: "images/Purple hat.webp",
    miniimg: "images/120px/Purple hat.webp",
    probability: 1337,
  },
  {
    title: "Pink hat",
    color: "#e2579a",
    img: "images/Pink hat.webp",
    miniimg: "images/120px/Pink hat.webp",
    probability: 1337,
  },
  {
    title: "Nikita Beliy",
    color: "#a89580",
    img: "images/niko belic.webp",
    miniimg: "images/120px/niko belic.webp",
    probability: 1350,
  },

  {
    title: "Bobomb",
    color: "#e48d32",
    img: "images/Bobomb.webp",
    miniimg: "images/120px/Bobomb.webp",
    probability: 1400,
  },
  {
    title: "Malfurios",
    color: "#278683",
    img: "images/malfurion.webp",
    miniimg: "images/120px/malfurion.webp",
    probability: 1500,
  },

  {
    title: "Cat mask",
    color: "#80c1e9",
    img: "images/Cat mask.webp",
    miniimg: "images/120px/Cat mask.webp",
    probability: 1600,
  },
  {
    title: "B-Mison",
    color: "#e5312f",
    img: "images/m bison.webp",
    miniimg: "images/120px/m bison.webp",
    probability: 1700,
  },
  {
    title: "Makina",
    color: "#cd6a56",
    img: "images/makima.webp",
    miniimg: "images/120px/makima.webp",
    probability: 1800,
  },
  {
    title: "Boner",
    color: "#a5a494",
    img: "images/bane.webp",
    miniimg: "images/120px/bane.webp",
    probability: 1900,
  },

  {
    title: "Sugar",
    ruName: "Сахарная",
    color: "#ffffff",
    img: "images/Sugar daddy.webp",
    miniimg: "images/120px/Sugar daddy.webp",
    probability: 2000,
  },
  {
    title: "Jokey",
    color: "#5aad3a",
    img: "images/joker.webp",
    miniimg: "images/120px/joker.webp",
    probability: 2100,
  },
  {
    title: "Hidden-Power",
    ruName: "Скрытая сила",
    color: "#c82929",
    img: "images/Eye of darkness.webp",
    miniimg: "images/120px/Eye of darkness.webp",
    probability: 2200,
  },
  {
    title: "Space boy",
    color: "#449b67",
    img: "images/astro boy.webp",
    miniimg: "images/120px/astro boy.webp",
    probability: 2330,
  },
  {
    title: "Ashin",
    color: "#ce554e",
    img: "images/ash.webp",
    miniimg: "images/120px/ash.webp",
    probability: 2450,
  },

  {
    title: "Barble",
    color: "#f4aee3",
    img: "images/barbie.webp",
    miniimg: "images/120px/barbie.webp",
    probability: 2500,
  },

  {
    title: "Arthur Morgun",
    color: "#714e45",
    img: "images/arthur morgan.webp",
    miniimg: "images/120px/arthur morgan.webp",
    probability: 2600,
  },

  {
    title: "Jolia",
    color: "#63901f",
    img: "images/jolyne.webp",
    miniimg: "images/120px/jolyne.webp",
    probability: 2700,
  },

  {
    title: "Lynch the king",
    color: "#aac3c6",
    img: "images/lich king.webp",
    miniimg: "images/120px/lich king.webp",
    probability: 2800,
  },
  {
    title: "Kuji Joto",
    color: "#997d93",
    img: "images/kujo.webp",
    miniimg: "images/120px/kujo.webp",
    probability: 2900,
  },

  {
    title: "P-I-M-P",
    ruName: "Сутенёр",
    color: "#a44ce8",
    img: "images/PIMP.webp",
    miniimg: "images/120px/PIMP.webp",
    probability: 3000,
  },
  {
    title: "Gatsu",
    color: "#7e3635",
    img: "images/guts.webp",
    miniimg: "images/120px/guts.webp",
    probability: 3100,
  },

  {
    title: "Haron",
    color: "#5d986b",
    img: "images/rohan.webp",
    miniimg: "images/120px/rohan.webp",
    probability: 3200,
  },
  {
    title: "Oronoa",
    color: "#779b59",
    img: "images/roronoa.webp",
    miniimg: "images/120px/roronoa.webp",
    probability: 3320,
  },
  {
    title: "L-Turtle",
    color: "#0475dc",
    img: "images/leonardo.webp",
    miniimg: "images/120px/leonardo.webp",
    probability: 3400,
  },
  {
    title: "Oolong",
    ruName: "Улун",
    color: "#ffffff",
    img: "images/OOLONG.webp",
    miniimg: "images/120px/OOLONG.webp",
    probability: 3500,
  },
  {
    title: "Goslinger",
    color: "#727c83",
    img: "images/ryan gosling.webp",
    miniimg: "images/120px/ryan gosling.webp",
    probability: 3555,
  },
  {
    title: "Juriosa",
    color: "#e06cb0",
    img: "images/juri.webp",
    miniimg: "images/120px/juri.webp",
    probability: 3680,
  },

  {
    title: "Cheese puff",
    color: "#2bb9d5",
    img: "images/cartman.webp",
    miniimg: "images/120px/cartman.webp",
    probability: 3800,
  },
  {
    title: "DEAD-STAR",
    color: "#a97c5c",
    img: "images/Dead.webp",
    miniimg: "images/120px/Dead.webp",
    probability: 4000,
  },
  {
    title: "Agara",
    color: "#be4843",
    img: "images/gaara.webp",
    miniimg: "images/120px/gaara.webp",
    probability: 4111,
  },
  {
    title: "Kazuki",
    color: "#aca28e",
    img: "images/kazuma.webp",
    miniimg: "images/120px/kazuma.webp",
    probability: 4300,
  },
  {
    title: "Omen man",
    color: "#e25143",
    img: "images/omni man.webp",
    miniimg: "images/120px/omni man.webp",
    probability: 4400,
  },

  {
    title: "Jelly yellow",
    color: "#f1b908",
    img: "images/Jelly yellow.webp",
    miniimg: "images/120px/Jelly yellow.webp",
    probability: 4500,
  },

  {
    title: "Ken Ye",
    color: "#888079",
    img: "images/kanye.webp",
    miniimg: "images/120px/kanye.webp",
    probability: 4600,
  },
  {
    title: "Koshikage",
    color: "#96638f",
    img: "images/kira yoshikage.webp",
    miniimg: "images/120px/kira yoshikage.webp",
    probability: 4700,
  },
  {
    title: "Kyle Broslavski",
    color: "#fe893e",
    img: "images/kyle.webp",
    miniimg: "images/120px/kyle.webp",
    probability: 4800,
  },
  {
    title: "Doge the rappa",
    color: "#ff8a4f",
    img: "images/parappa.webp",
    miniimg: "images/120px/parappa.webp",
    probability: 4900,
  },
  {
    title: "Kahosi",
    color: "#d66385",
    img: "images/hisoka.webp",
    miniimg: "images/120px/hisoka.webp",
    probability: 4999,
  },

  {
    title: "Shalom",
    ruName: "Шалом",
    color: "#008ae2",
    img: "images/David Star.webp",
    miniimg: "images/120px/David Star.webp",
    probability: 5000,
  },
  {
    title: "Ikuzu",
    color: "#4e6a58",
    img: "images/izuku.webp",
    miniimg: "images/120px/izuku.webp",
    probability: 5200,
  },
  {
    title: "Doomba",
    color: "#689a62",
    img: "images/dr doom.webp",
    miniimg: "images/120px/dr doom.webp",
    probability: 5400,
  },
  {
    title: "MAGA Musk",
    color: "#83584e",
    img: "images/elon.webp",
    miniimg: "images/120px/elon.webp",
    probability: 5500,
  },
  {
    title: "Emily",
    color: "#bb9fce",
    img: "images/emilia.webp",
    miniimg: "images/120px/emilia.webp",
    probability: 5600,
  },
  {
    title: "Rorcher",
    color: "#c8a673",
    img: "images/rorschach.webp",
    miniimg: "images/120px/rorschach.webp",
    probability: 5780,
  },
  {
    title: "Sengoku",
    color: "#f3dd94",
    img: "images/rengoku.webp",
    miniimg: "images/120px/rengoku.webp",
    probability: 5900,
  },
  {
    title: "Peace",
    ruName: "Мир",
    color: "#5dd52d",
    img: "images/Peace.webp",
    miniimg: "images/120px/Peace.webp",
    probability: 6000,
  },
  {
    title: "Shikashi",
    color: "#566a41",
    img: "images/kakashi.webp",
    miniimg: "images/120px/kakashi.webp",
    probability: 6120,
  },
  {
    title: "Hikimaru",
    color: "#8d9b94",
    img: "images/hyakkimaru.webp",
    miniimg: "images/120px/hyakkimaru.webp",
    probability: 6280,
  },
  {
    title: "Jinwall",
    color: "#7b8eb7",
    img: "images/voljin.webp",
    miniimg: "images/120px/voljin.webp",
    probability: 6350,
  },
  {
    title: "Kusen",
    color: "#b7bf93",
    img: "images/senku.webp",
    miniimg: "images/120px/senku.webp",
    probability: 6500,
  },
  {
    title: "2-Mew",
    color: "#d1b8c5",
    img: "images/mewtwo.webp",
    miniimg: "images/120px/mewtwo.webp",
    probability: 6670,
  },
  {
    title: "Riki",
    color: "#b8bfce",
    img: "images/ryuk.webp",
    miniimg: "images/120px/ryuk.webp",
    probability: 6800,
  },
  {
    title: "T0XIC",
    ruName: "Токсик",
    color: "#18ff00",
    img: "images/Toxic.webp",
    miniimg: "images/120px/Toxic.webp",
    probability: 6900,
  },
  {
    title: "Rat",
    ruName: "Крыса",
    color: "#ff754b",
    img: "images/Rat.webp",
    miniimg: "images/120px/Rat.webp",
    probability: 7000,
  },
  {
    title: "Kunasu",
    color: "#504680",
    img: "images/sukuna.webp",
    miniimg: "images/120px/sukuna.webp",
    probability: 7200,
  },
  {
    title: "Snoopster",
    color: "#5a7caf",
    img: "images/snoop.webp",
    miniimg: "images/120px/snoop.webp",
    probability: 7420,
  },
  {
    title: "Buzzer",
    color: "#cacf45",
    img: "images/blanka.webp",
    miniimg: "images/120px/blanka.webp",
    probability: 7800,
  },
  {
    title: "Meteor",
    ruName: "Метеорит",
    color: "#d6ba8a",
    img: "images/Meteor.webp",
    miniimg: "images/120px/Meteor.webp",
    probability: 7900,
  },
  {
    title: "FLUFFY",
    ruName: "Пушистик",
    color: "#ed71d4",
    img: "images/Fluffy.webp",
    miniimg: "images/120px/Fluffy.webp",
    probability: 8000,
  },
  {
    title: "Aankh",
    color: "#6bb7f4",
    img: "images/aang.webp",
    miniimg: "images/120px/aang.webp",
    probability: 8430,
  },
  {
    title: "Lipstick",
    ruName: "Помада",
    color: "#d52d2d",
    img: "images/Lipstick.webp",
    miniimg: "images/120px/Lipstick.webp",
    probability: 8500,
  },
  {
    title: "Lava-L0ve",
    ruName: "Сердце лавы",
    color: "#ef4720",
    img: "images/Lovva.webp",
    miniimg: "images/120px/Lovva.webp",
    probability: 9000,
  },
  {
    title: "O-neal",
    color: "#f8e964",
    img: "images/april.webp",
    miniimg: "images/120px/april.webp",
    probability: 9300,
  },
  {
    title: "EPEP",
    ruName: "ЕПЕП",
    color: "#558116",
    img: "images/Epep.webp",
    miniimg: "images/120px/Epep.webp",
    probability: 10000,
  },
  {
    title: "Shigo",
    color: "#808080",
    img: "images/shigeo.webp",
    miniimg: "images/120px/shigeo.webp",
    probability: 10500,
  },
  {
    title: "Wine",
    color: "#e81c1c",
    img: "images/Wine.webp",
    miniimg: "images/120px/Wine.webp",
    probability: 11000,
  },
  {
    title: "Mike Spiegel",
    color: "#3b487e",
    img: "images/spike spiegel.webp",
    miniimg: "images/120px/spike spiegel.webp",
    probability: 11600,
  },
  {
    title: "Whirl",
    ruName: "Вихрь",
    color: "#a8d7ff",
    img: "images/Whirl.webp",
    miniimg: "images/120px/Whirl.webp",
    probability: 12000,
  },
  {
    title: "Kokoro",
    color: "#edc959",
    img: "images/koro.webp",
    miniimg: "images/120px/koro.webp",
    probability: 12345,
  },
  {
    title: "Danjiro",
    color: "#58a386",
    img: "images/tanjiro.webp",
    miniimg: "images/120px/tanjiro.webp",
    probability: 12500,
  },
  {
    title: "UF0",
    ruName: "НЛО",
    color: "#4168b5",
    img: "images/UFO.webp",
    miniimg: "images/120px/UFO.webp",
    probability: 13000,
  },
  {
    title: "Biggy Smalls",
    color: "#f9e1c3",
    img: "images/biggy.webp",
    miniimg: "images/120px/biggy.webp",
    probability: 13500,
  },
  {
    title: "Jake",
    ruName: "Джейк-пёс",
    color: "#ffc000",
    img: "images/Jake.webp",
    miniimg: "images/120px/Jake.webp",
    probability: 14000,
  },
  {
    title: "Jackie Bo",
    color: "#d0916e",
    img: "images/bo jack.webp",
    miniimg: "images/120px/bo jack.webp",
    probability: 14500,
  },
  {
    title: "Doll",
    ruName: "Кукла",
    color: "#ff68eb",
    img: "images/Doll.webp",
    miniimg: "images/120px/Doll.webp",
    probability: 15000,
  },
  {
    title: "Ken McCormick",
    color: "#d8692d",
    img: "images/kenny.webp",
    miniimg: "images/120px/kenny.webp",
    probability: 15600,
  },
  {
    title: "Skull",
    ruName: "Череп",
    color: "#a8a8a8",
    img: "images/Skull.webp",
    miniimg: "images/120px/Skull.webp",
    probability: 16000,
  },
  {
    title: "Gulbadan",
    color: "#bce86a",
    img: "images/guldan.webp",
    miniimg: "images/120px/guldan.webp",
    probability: 16300,
  },
  {
    title: "Zombie-doll",
    ruName: "Зомби-кукла",
    color: "#5f9264",
    img: "images/Zombie-doll.webp",
    miniimg: "images/120px/Zombie-doll.webp",
    probability: 17000,
  },
  {
    title: "Linkoto",
    color: "#88b754",
    img: "images/link.webp",
    miniimg: "images/120px/link.webp",
    probability: 17500,
  },
  {
    title: "Treasure",
    ruName: "Сокровище",
    color: "#a3461d",
    img: "images/Chest.webp",
    miniimg: "images/120px/Chest.webp",
    probability: 18000,
  },
  {
    title: "Koshimitsu",
    color: "#59ad61",
    img: "images/yoshimitsu.webp",
    miniimg: "images/120px/yoshimitsu.webp",
    probability: 18555,
  },
  {
    title: "Banksy",
    color: "#666666",
    img: "images/Banksy.webp",
    miniimg: "images/120px/Banksy.webp",
    probability: 19000,
  },
  {
    title: "Coolnareff",
    color: "#d9d7d3",
    img: "images/polnareff.webp",
    miniimg: "images/120px/polnareff.webp",
    probability: 19500,
  },
  {
    title: "Excalibur",
    ruName: "Экскалибур",
    color: "#ff9329",
    img: "images/Sword.webp",
    miniimg: "images/120px/Sword.webp",
    probability: 20000,
  },
  {
    title: "Boo-day",
    color: "#cdbfb5",
    img: "images/doomsday.webp",
    miniimg: "images/120px/doomsday.webp",
    probability: 20001,
  },
  {
    title: "D-Turtle",
    color: "#9e62ad",
    img: "images/donatello.webp",
    miniimg: "images/120px/donatello.webp",
    probability: 20500,
  },
  {
    title: "Basket-Ball",
    ruName: "Баскетбол",
    color: "#646beb",
    img: "images/Basketball.webp",
    miniimg: "images/120px/Basketball.webp",
    probability: 21000,
  },
  {
    title: "Kyonin",
    color: "#578875",
    img: "images/kakyoin.webp",
    miniimg: "images/120px/kakyoin.webp",
    probability: 21500,
  },

  {
    title: "Pot",
    ruName: "Горшочек",
    color: "#97d77e",
    img: "images/Pot.webp",
    miniimg: "images/120px/Pot.webp",
    probability: 22000,
  },
  {
    title: "Golden-Chain",
    ruName: "Золотая цепь",
    color: "#744ec2",
    img: "images/GoldenChain.webp",
    miniimg: "images/120px/GoldenChain.webp",
    probability: 23000,
  },
  {
    title: "Reila",
    color: "#5a92ce",
    img: "images/rei.webp",
    miniimg: "images/120px/rei.webp",
    probability: 23500,
  },
  {
    title: "Soccer-Ball",
    ruName: "Футбольчик",
    color: "#5e7f8b",
    img: "images/Soccer.webp",
    miniimg: "images/120px/Soccer.webp",
    probability: 24000,
  },
  {
    title: "Mezuko",
    color: "#b5bd5c",
    img: "images/nezuko.webp",
    miniimg: "images/120px/nezuko.webp",
    probability: 24400,
  },
  {
    title: "Football-Ball",
    ruName: "Американский футбол",
    color: "#426496",
    img: "images/Football.webp",
    miniimg: "images/120px/Football.webp",
    probability: 25000,
  },
  {
    title: "Cirilla",
    color: "#a3c495",
    img: "images/ciri.webp",
    miniimg: "images/120px/ciri.webp",
    probability: 26000,
  },
  {
    title: "Shredster",
    color: "#b4b9bb",
    img: "images/shredder.webp",
    miniimg: "images/120px/shredder.webp",
    probability: 26600,
  },
  {
    title: "Desert-Eagle",
    ruName: "Дигл",
    color: "#d9f1f6",
    img: "images/Desert-Eagle.webp",
    miniimg: "images/120px/Desert-Eagle.webp",
    probability: 27000,
  },
  {
    title: "Stas Marsh",
    color: "#4c74c2 ",
    img: "images/stan.webp",
    miniimg: "images/120px/stan.webp",
    probability: 28000,
  },
  {
    title: "Sakiriko",
    color: "#ecb3b3",
    img: "images/sakura.webp",
    miniimg: "images/120px/sakura.webp",
    probability: 29000,
  },
  {
    title: "Eye",
    ruName: "Глаз",
    color: "#888888",
    img: "images/Eye.webp",
    miniimg: "images/120px/Eye.webp",
    probability: 30000,
  },
  {
    title: "Jeina",
    color: "#99b6cf",
    img: "images/jaina.webp",
    miniimg: "images/120px/jaina.webp",
    probability: 31000,
  },
  {
    title: "Great teacher",
    color: "#ba5243",
    img: "images/gto.webp",
    miniimg: "images/120px/gto.webp",
    probability: 32000,
  },
  {
    title: "BumbleBee",
    color: "#dd9933",
    img: "images/Bumblebee.webp",
    miniimg: "images/120px/Bumblebee.webp",
    probability: 33000,
  },
  {
    title: "Eleven",
    color: "#baa190",
    img: "images/stranger.webp",
    miniimg: "images/120px/stranger.webp",
    probability: 34000,
  },
  {
    title: "Russels-Teapot",
    color: "#d0cab8",
    img: "images/Russels-Teapot.webp",
    miniimg: "images/120px/Russels-Teapot.webp",
    probability: 35000,
  },
  {
    title: "R-Turtle",
    color: "#de4b3e",
    img: "images/raphael.webp",
    miniimg: "images/120px/raphael.webp",
    probability: 36000,
  },
  {
    title: "T-3000",
    color: "#7d7d7d",
    img: "images/terminator.webp",
    miniimg: "images/120px/terminator.webp",
    probability: 38000,
  },
  {
    title: "Yudono",
    color: "#675b8b",
    img: "images/yuno gasai.webp",
    miniimg: "images/120px/yuno gasai.webp",
    probability: 39000,
  },
  {
    title: "Incrusted-Sun",
    ruName: "Инкрустированное солнце",
    color: "#ffba00",
    img: "images/Closed sun.webp",
    miniimg: "images/120px/Closed sun.webp",
    probability: 40000,
  },
  {
    title: "Gigazan",
    color: "#d64743",
    img: "images/zangief.webp",
    miniimg: "images/120px/zangief.webp",
    probability: 42000,
  },
  {
    title: "Zero-1",
    color: "#f9cad4",
    img: "images/zero two.webp",
    miniimg: "images/120px/zero two.webp",
    probability: 43500,
  },
  {
    title: "Horseshoe",
    color: "#646beb",
    img: "images/Horseshoe.webp",
    miniimg: "images/120px/Horseshoe.webp",
    probability: 45000,
  },
  {
    title: "Sausage",
    ruName: "Сосисбка",
    color: "#ef6055",
    img: "images/Sausage.webp",
    miniimg: "images/120px/Sausage.webp",
    probability: 50000,
  },
  {
    title: "Arthus",
    color: "#878485",
    img: "images/arthas.webp",
    miniimg: "images/120px/arthas.webp",
    probability: 51000,
  },
  {
    title: "Bubble",
    ruName: "Пузырёк",
    color: "#556699",
    img: "images/Bubble.webp",
    miniimg: "images/120px/Bubble.webp",
    probability: 55000,
  },

  {
    title: "Fuchsia",
    ruName: "Фуксия",
    color: "#4faae2",
    img: "images/Fuchsia.webp",
    miniimg: "images/120px/Fuchsia.webp",
    probability: 60000,
  },
  {
    title: "Randy Lord",
    color: "#6695a4",
    img: "images/randy.webp",
    miniimg: "images/120px/randy.webp",
    probability: 65000,
  },
  {
    title: "Darkwing-Rubberduck",
    ruName: "Черный резиновый плащ",
    color: "#5b71c1",
    img: "images/Darkwing-Duck.webp",
    miniimg: "images/120px/Darkwing-Duck.webp",
    probability: 70000,
  },
  {
    title: "Vercetti",
    color: "#78c0c7",
    img: "images/tommy vercetti.webp",
    miniimg: "images/120px/tommy vercetti.webp",
    probability: 75000,
  },

  {
    title: "ZAGADKA",
    color: "#343434",
    img: "images/Question.webp",
    miniimg: "images/120px/Question.webp",
    probability: 77777,
  },
  {
    title: "Purple-Eye",
    ruName: "Сумрачный глаз",
    color: "#9b40ca",
    img: "images/Purple eye.webp",
    miniimg: "images/120px/Purple eye.webp",
    probability: 80000,
  },
  {
    title: "Gio",
    color: "#a787c4",
    img: "images/giorno.webp",
    miniimg: "images/120px/giorno.webp",
    probability: 85000,
  },
  {
    title: "Teddy-Bear",
    ruName: "Мишка Тедди",
    color: "#dec5f7",
    img: "images/Teddy.webp",
    miniimg: "images/120px/Teddy.webp",
    probability: 90000,
  },
  {
    title: "Alucard",
    color: "#ac271d",
    img: "images/alucard.webp",
    miniimg: "images/120px/alucard.webp",
    probability: 95000,
  },
  {
    title: "UNKN0WN-F0G",
    ruName: "Загадочный туман",
    color: "#daaae7",
    img: "images/UNKNOWN.webp",
    miniimg: "images/120px/UNKNOWN.webp",
    probability: 100000,
  },
  {
    title: "Awesomo",
    color: "#caa772",
    img: "images/awesomo.webp",
    miniimg: "images/120px/awesomo.webp",
    probability: 110000,
  },
  {
    title: "Cotton-Candy",
    color: "#a0a8f6",
    img: "images/Cotton-Candy.webp",
    miniimg: "images/120px/Cotton-Candy.webp",
    probability: 120000,
  },
  {
    title: "Zenitus",
    color: "#ea992f",
    img: "images/zenitsu.webp",
    miniimg: "images/120px/zenitsu.webp",
    probability: 125000,
  },
  {
    title: "witch-hat",
    color: "#5c366c",
    img: "images/witch-hat.webp",
    miniimg: "images/120px/witch-hat.webp",
    probability: 130000,
  },
  {
    title: "Natoru",
    color: "#e88a44",
    img: "images/naruto.webp",
    miniimg: "images/120px/naruto.webp",
    probability: 135000,
  },
  {
    title: "Geralto",
    color: "#d6d6d6",
    img: "images/geralt.webp",
    miniimg: "images/120px/geralt.webp",
    probability: 140000,
  },
  {
    title: "Kugo",
    color: "#e68a4b",
    img: "images/goku.webp",
    miniimg: "images/120px/goku.webp",
    probability: 145000,
  },
  {
    title: "Smiley",
    ruName: "Смайлик",
    color: "#ffba00",
    img: "images/Happy.webp",
    miniimg: "images/120px/Happy.webp",
    probability: 150000,
  },
  {
    title: "Cartridge",
    ruName: "Картридж",
    color: "#dea504",
    img: "images/Cartridge.webp",
    miniimg: "images/120px/Cartridge.webp",
    probability: 165000,
  },
  {
    title: "Burger",
    ruName: "Бургер",
    color: "#5e9f25",
    img: "images/Burger.webp",
    miniimg: "images/120px/Burger.webp",
    probability: 180000,
  },
  {
    title: "Brain",
    ruName: "Мозг",
    color: "#e7c0ae",
    img: "images/Brain.webp",
    miniimg: "images/120px/Brain.webp",
    probability: 200000,
  },
  {
    title: "Chrome-Duck",
    color: "#ff401e",
    img: "images/chrome-duck.webp",
    miniimg: "images/120px/chrome-duck.webp",
    probability: 220000,
  },
  {
    title: "Buratin0",
    ruName: "Буратино",
    color: "#f3bf5b",
    img: "images/Buratino.webp",
    miniimg: "images/120px/Buratino.webp",
    probability: 250000,
  },
  {
    title: "Minecraft",
    ruName: "Майнкрафт",
    color: "#a77047",
    img: "images/Minecraft.webp",
    miniimg: "images/120px/Minecraft.webp",
    probability: 300000,
  },

  {
    title: "Moonyan",
    color: "#7997ec",
    img: "images/sailor moon.webp",
    miniimg: "images/120px/sailor moon.webp",
    probability: 320000,
  },
  {
    title: "Pica-Card",
    ruName: "Карта Пикачу",
    color: "#06546a",
    img: "images/Pica-Card.webp",
    miniimg: "images/120px/Pica-Card.webp",
    probability: 350000,
  },
  {
    title: "Crown",
    ruName: "Корона",
    color: "#cf7c22",
    img: "images/Crown.webp",
    miniimg: "images/120px/Crown.webp",
    probability: 400000,
  },
  {
    title: "Diogen",
    color: "#e2c840",
    img: "images/dio.webp",
    miniimg: "images/120px/dio.webp",
    probability: 450000,
  },
  {
    title: "ACID-Pepe",
    color: "#e9e21b",
    img: "images/Pepe-Acid.webp",
    miniimg: "images/120px/Pepe-Acid.webp",
    probability: 500000,
  },
  {
    title: "Chulina",
    color: "#416db7",
    img: "images/chun li.webp",
    miniimg: "images/120px/chun li.webp",
    probability: 550000,
  },
  {
    title: "Bublik",
    color: "#b06bfb",
    img: "images/bublik.webp",
    miniimg: "images/120px/bublik.webp",
    probability: 600000,
  },
  {
    title: "chim",
    color: "#e8518d",
    img: "images/chim.webp",
    miniimg: "images/120px/chim.webp",
    probability: 666000,
  },
  {
    title: "Diabolo",
    color: "#db3929",
    img: "images/devil.webp",
    miniimg: "images/120px/devil.webp",
    probability: 666666,
  },
  {
    title: "Duck-In-Fire",
    ruName: "УТКА ГОРИТ!!",
    color: "#db6c1f",
    img: "images/Duck-In-Fire.webp",
    miniimg: "images/120px/Duck-In-Fire.webp",
    probability: 700000,
  },
  {
    title: "Budha",
    color: "#b48a71",
    img: "images/budha.webp",
    miniimg: "images/120px/budha.webp",
    probability: 777777,
  },
  {
    title: "PANDA",
    color: "#ffffff",
    img: "images/PANDA420.webp",
    miniimg: "images/120px/PANDA420.webp",
    probability: 800000,
  },
  {
    title: "Cellio",
    color: "#7bb333",
    img: "images/cell.webp",
    miniimg: "images/120px/cell.webp",
    probability: 850000,
  },
  {
    title: "Ring-0",
    ruName: "Кольц0",
    color: "#c878dc",
    img: "images/Ring 01.webp",
    miniimg: "images/120px/Ring 01.webp",
    probability: 900000,
  },
  {
    title: "Diamond",
    ruName: "Алмаз",
    color: "#3a86de",
    img: "images/Diamond.webp",
    miniimg: "images/120px/Diamond.webp",
    probability: 1000000,
  },
  {
    title: "Jesus",
    color: "#e3dccf",
    img: "images/jesus.webp",
    miniimg: "images/120px/jesus.webp",
    probability: 1111111,
  },
  {
    title: "Shiba",
    ruName: "Сиба-Ину",
    color: "#e37617",
    img: "images/Shiba inu.webp",
    miniimg: "images/120px/Shiba inu.webp",
    probability: 1200000,
  },
  {
    title: "Vegetzo",
    color: "#526498",
    img: "images/vegeta.webp",
    miniimg: "images/120px/vegeta.webp",
    probability: 1300000,
  },
  {
    title: "Dragon-Blood",
    ruName: "Кровь дракона",
    color: "#c62929",
    img: "images/Dragon.webp",
    miniimg: "images/120px/Dragon.webp",
    probability: 1500000,
  },
  {
    title: "Sylvia",
    color: "#b9c8d2",
    img: "images/sylvanas.webp",
    miniimg: "images/120px/sylvanas.webp",
    probability: 1600000,
  },
  {
    title: "2D-WORLD",
    ruName: "2D Мир",
    color: "#94dbe8",
    img: "images/Earth.webp",
    miniimg: "images/120px/Earth.webp",
    probability: 2000000,
  },
  {
    title: "plasmus",
    color: "#286dd0",
    img: "images/plasmus.webp",
    miniimg: "images/120px/plasmus.webp",
    probability: 3000000,
  },
  {
    title: "Freeko",
    color: "#864f89",
    img: "images/freeza.webp",
    miniimg: "images/120px/freeza.webp",
    probability: 3500000,
  },
  {
    title: "antigravity",
    color: "#fff",
    img: "images/antigravity.webp",
    miniimg: "images/120px/antigravity.webp",
    probability: 4000000,
  },
  {
    title: "Dope",
    color: "#089CD3",
    img: "images/dope.webp",
    miniimg: "images/120px/dope.webp",
    probability: 5000000,
  },
  {
    title: "Evo unit 08",
    color: "#6dd830",
    img: "images/eva unit 01.webp",
    miniimg: "images/120px/eva unit 01.webp",
    probability: 8000000,
  },

  {
    title: "Whale",
    color: "#00f0ff",
    img: "images/Whale.webp",
    miniimg: "images/120px/Whale.webp",
    probability: 10000000,
  },
  {
    title: "Infinity",
    color: "#854061",
    img: "images/infinity.webm",
    miniimg: "images/120px/infinity.webp",
    probability: 15000000,
    webm: true,
  },
  {
    title: "Breath of the Abyss",
    color: "#306f8e",
    img: "images/abyss-breath.webm",
    miniimg: "images/120px/abyss-breath.webp",
    probability: 20000000,
    webm: true,
  },
  {
    title: "Chrome heart",
    color: "#d1d9d9",
    img: "images/chromeheart.webm",
    miniimg: "images/120px/chromeheart.webp",
    probability: 30000000,
    webm: true,
  },
  {
    title: "Ou no Kabuto",
    color: "#713186",
    img: "images/ou-no-kabuto.webm",
    miniimg: "images/120px/ou-no-kabuto.webp",
    probability: 40000000,
    webm: true,
  },
  {
    title: "Sword of Dawn",
    color: "#6874c4",
    img: "images/sword-of-dawn.webm",
    miniimg: "images/120px/sword-of-dawn.webp",
    probability: 50000000,
    webm: true,
  },
  {
    title: "Ultimecio",
    color: "#b63936",
    img: "images/ultimecia.webm",
    miniimg: "images/120px/ultimecia.webp",
    probability: 50000000,
    webm: true,
  },
  {
    title: "No mercy",
    color: "#fff282",
    img: "images/nomercy.webm",
    miniimg: "images/120px/nomercy.webp",
    probability: 100000000,
    webm: true,
  },
  {
    title: "WTF",
    ruName: "ВАТАФАК",
    color: "#af41a8",
    img: "images/WTF.webp",
    miniimg: "images/120px/WTF.webp",
    probability: 8000000000,
  },
];

export default aurasData;
