const loosingPhrases = [
  "Это провал!",
  "ЛЕГЕНДАРНЫЙ\nПРОИГРЫШ",
  "Казна пустеет\nмилорд",
  "Вот это хорошо! Для моего кошелька.",
  "Крах!",
  "Может на тебе\nпроклятие?",
  "Безнадёга!",
  "Досадно\nну ладно",
  "Мда..",
  "Не вышло!",
  "Жалкое зрелище",
  "Ну как так-то?!",
  "Не твой день",
  "Давай ещё раз",
  "Бесполезно",
  "Я ожидал другого",
  "О, почти джекпот!",
  "У тебя есть стратегия?",
  "Могло быть и хуже. Или нет",
  "Поздно просить пощады",
  "Ты не стараешься",
  "Это вообще законно?",
  "Что, опять?",
  "Не расстраивайся, ты не один такой.",
  "А всё так хорошо начиналось...",
  "Рандом — твой враг.",
  "Легенды будут слагать о твоих поражениях.",
  "Просто не твой день.",
  "Вот ты и узнал как не надо делать",
  "Шутки кончились, но не про тебя.",
  "О, это было эпично… и печально.",
  "Ты явно в ударе",
  "Время вызывать подкрепление.",
  "Кажись подкрутили!",
  "Ты точно не сдашься?",
];
export default loosingPhrases;
