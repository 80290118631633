const TakeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M16.2,0.5c2,7.3,1.3,15.1,1.8,22.7c2.1-2.8,4.1-13.1,8.3-11.3c0.5,6.4-5.1,12.2-7,18.3c-0.5,1.6-4.9,2.1-5.2,0.3
		c-2.4-5.1-7.1-8.7-8.9-14.3c0-0.2,0-0.5,0-0.7c0.4-0.2,0.3-0.7,0.8-0.7c0.9-0.4,1.4-1.1,2.5-1.1c2.1,1.7,3.4,4.7,5.1,6.9
		c0.1-6.2-0.8-12.6-0.9-18.6c0.2-0.5,0.8-0.3,1.1-0.6C14.4,0.5,15.4,0.5,16.2,0.5z"
      />
    </g>
  </svg>
);

export default TakeIcon;
