import React, { useState, useEffect } from "react";
import "./App.css";

import aurasData from "./auras.js";
import { useTranslation } from "react-i18next";

const getAuraByName = async (value) => {
  for (var i = 0; i < aurasData.length; i++) {
    if (aurasData[i]["title"] === value) {
      return aurasData[i];
    }
  }
  return null;
};

const getAuraColor = async (player) => {
  const aura = await getAuraByName(player.attributes.mainAura);

  return aura.color;
};

const PlayerListItem = ({ player, index, tgId, type }) => {
  const { t } = useTranslation();
  const [auraColor, setAuraColor] = useState("");

  useEffect(() => {
    const fetchAuraColor = async () => {
      const color = await getAuraColor(player);
      setAuraColor(color);
    };
    fetchAuraColor();
  }, [player]);

  function formatValue(value) {
    if (value >= 1_000_000_000) {
      return (value / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "b";
    } else if (value >= 1_000_000) {
      return (value / 1_000_000).toFixed(1).replace(/\.0$/, "") + "m";
    } else if (value >= 1_000) {
      return (value / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
    }
    return value.toString();
  }

  return (
    <li
      key={index}
      className="top-player-li"
      style={{ backgroundColor: auraColor }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{index + 1 + "." + player.attributes.firstName}</div>
          {tgId.toString() === player.attributes.tgId && (
            <div className="top-players-you">{t("you")}</div>
          )}
        </div>

        <div>
          {type === "AuraRng" &&
            "(" +
              t(player.attributes.mainAura) +
              ") " +
              formatValue(player.attributes.mainAuraRarity)}
          {type === "Slots" && formatValue(player.attributes.ducks)}
        </div>
      </div>
      {player.attributes.description && (
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.5)",
            padding: "2px 8px",
            marginTop: "4px",
            borderRadius: "6px",
          }}
        >
          {player.attributes.description}
        </div>
      )}
    </li>
  );
};

export default PlayerListItem;
