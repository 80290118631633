const tailorItems = [
  {
    title: "Leather glove",
    color: "#e0cfb0",
    img: "glove-images/leather-glove.webp",
    miniimg: "glove-images/120px/leather-glove.webp",
    type: "glove",
    needItems: [
      { title: "Base element", type: "craft", quantity: 10 },
      { title: "bestCombo", type: "stats", quantity: 50 },
      { title: "spins", type: "stats", quantity: 10000 },
    ],
    cost: 1000,
  },
  {
    title: "Lucky glove",
    description: "",
    color: "#1b4b2b",
    img: "glove-images/lucky-glove.webp",
    miniimg: "glove-images/120px/lucky-glove.webp",
    type: "glove",
    needItems: [
      { title: "Base element", type: "craft", quantity: 15 },
      { title: "spins", type: "stats", quantity: 50000 },
      { title: "Small Lucky Potion", type: "potion", quantity: 25 },
      { title: "Small Speed Potion", type: "potion", quantity: 25 },
    ],
    cost: 10000,
  },
  {
    title: "Metal glove",
    color: "#284854",
    img: "glove-images/metal-glove.webp",
    miniimg: "glove-images/120px/metal-glove.webp",
    type: "glove",
    needItems: [
      { title: "Base element", type: "craft", quantity: 20 },
      { title: "spins", type: "stats", quantity: 100000 },
      { title: "Leather glove", type: "glove", quantity: 1 },
    ],
    cost: 25000,
  },
  {
    title: "Metal magic glove",
    color: "#3dacc9",
    img: "glove-images/metal-magic-glove.webp",
    miniimg: "glove-images/120px/metal-magic-glove.webp",
    type: "glove",
    needItems: [
      { title: "Base element", type: "craft", quantity: 10 },
      { title: "spins", type: "stats", quantity: 150000 },
      { title: "Metal glove", type: "glove", quantity: 1 },
    ],
    cost: 30000,
  },

  {
    title: "Magic glove",
    color: "#121b1f",
    img: "glove-images/magic-glove.webp",
    miniimg: "glove-images/120px/magic-glove.webp",
    type: "glove",
    needItems: [
      { title: "bestCombo", type: "stats", quantity: 100 },
      { title: "Base element", type: "craft", quantity: 10 },
      { title: "spins", type: "stats", quantity: 200000 },
      { title: "Metal magic glove", type: "glove", quantity: 1 },
    ],
    cost: 50000,
  },
  {
    title: "Mecha glove",
    color: "#e0cfb0",
    img: "glove-images/mecha-glove.webp",
    miniimg: "glove-images/120px/mecha-glove.webp",
    type: "glove",
    needItems: [
      { title: "Base element", type: "craft", quantity: 20 },
      { title: "spins", type: "stats", quantity: 250000 },
      { title: "Magic glove", type: "glove", quantity: 1 },
    ],
    cost: 100000,
  },
  {
    title: "Rainbow glove",
    color: "#0a1620",
    img: "glove-images/rainbow-glove.webp",
    miniimg: "glove-images/120px/rainbow-glove.webp",
    type: "glove",
    needItems: [
      { title: "Base element", type: "craft", quantity: 50 },
      { title: "spins", type: "stats", quantity: 400000 },
      { title: "Mecha glove", type: "glove", quantity: 1 },
      { title: "Brain", type: "aura", quantity: 1 },
      { title: "Smiley", type: "aura", quantity: 1 },
      { title: "Chrome-Duck", type: "aura", quantity: 1 },
      { title: "Lava-L0ve", type: "aura", quantity: 25 },
      { title: "Saint", type: "aura", quantity: 150 },
    ],
    cost: 1000000,
  },
];
export default tailorItems;
