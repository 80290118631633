export const shopBuyPhrases = [
  "ваууу здесь так мНОга деньг... может ли Эби их пренять стока",
  "нАрмальна поторговали!! прихади еще",
  "у меня сТОка мног всего, ты бери бери",
  "Эби может продать лЮбую вещ!! Я у4ила многа штук",
  "вот ето да, сКОра накоплю на образаванее!",
  "зелья ваще КЛАСс!",
  "скора акция ДВА ПО ЦЕНЕ трёх, приглошаю",
  "вОТ так спасиба4киии",
  "ЙЕЕЙоооо я БОГАТАЯяя!",
  "спасибо ПОКУПКУ офорырв!1",
  "у тя ваще сКОКа деняк есть?",
  "у меНЯ ещё мног зельев",
  "ищо",
  "ЭБИ, то есть я предлогаю покупать ЧАЩе",
  "еее вУНШ пунш!",
  "да я хароШая продаВ4иха",
];

export const shopNotEnoughPhrases = [
  "ето шутка?? ХАХА очень смешно, толька у меня есть калбкулятор",
  "АХРАНААААААААААААААААААААААААААА",
  "Эби не аСУЖДАЕТ без деняг, но НЕ продаст без деняг",
  "мНЕ так нелбзя прадавать",
  "можт найДЁШ работу?? или иди к гусю Джо он БРО",
  "такккккккк щя огрЕБЕШ",
  "такс что тут у нассс ага ОБМАН",
  "ага дА атли4на, вот держите ноль зельев",
];
