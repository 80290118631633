// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.luck {
  font-family: "Spline Sans Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  position: fixed;
  font-size: 24px;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  animation: flyUp 2s forwards;
  pointer-events: none; /* Чтобы текст не перехватывал клики */
  z-index: 1;
}

@keyframes flyUp {
  0% {
    opacity: 1;
    transform: translateY(-60px);
  }
  50% {
    opacity: 1;
    transform: translateY(-180px);
  }
  80% {
    opacity: 1;
    transform: translateY(-180px);
  }
  100% {
    opacity: 0;
    transform: translateY(-180px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/LuckAnimation.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,oBAAoB,EAAE,sCAAsC;EAC5D,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,6BAA6B;EAC/B;EACA;IACE,UAAU;IACV,6BAA6B;EAC/B;EACA;IACE,UAAU;IACV,6BAA6B;EAC/B;AACF","sourcesContent":[".luck {\r\n  font-family: \"Spline Sans Mono\", monospace;\r\n  font-optical-sizing: auto;\r\n  font-weight: 400;\r\n  position: fixed;\r\n  font-size: 24px;\r\n  color: rgb(255, 255, 255);\r\n  display: flex;\r\n  align-items: center;\r\n  animation: flyUp 2s forwards;\r\n  pointer-events: none; /* Чтобы текст не перехватывал клики */\r\n  z-index: 1;\r\n}\r\n\r\n@keyframes flyUp {\r\n  0% {\r\n    opacity: 1;\r\n    transform: translateY(-60px);\r\n  }\r\n  50% {\r\n    opacity: 1;\r\n    transform: translateY(-180px);\r\n  }\r\n  80% {\r\n    opacity: 1;\r\n    transform: translateY(-180px);\r\n  }\r\n  100% {\r\n    opacity: 0;\r\n    transform: translateY(-180px);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
