const TailorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M1.2,9.9c0-1.5,0.4-2.7,1.6-3.5C3.7,5.8,4.7,5.7,5.7,6c1.7,0.5,3.1,1.5,4.5,2.6c1.4,1.2,2.8,2.6,4.2,3.8
		c0.4,0.4,0.4,0.4,0.8,0c1-1.1,2-2.2,3.1-3.3c1-1.1,2.1-2.1,3.2-3.2c1.3-1.2,2.7-2.5,4-3.6c0.7-0.6,1.4-1.1,2.3-1.4
		c0.7-0.2,1.3-0.2,1.8,0.3c0.5,0.4,0.9,0.8,1.2,1.3c0.5,0.7,0.3,1.5-0.1,2.2c-0.6,0.9-1.2,1.9-2,2.7c-2,2.2-4.1,4.3-6.2,6.4
		c-1,1.1-2.2,2.1-3.3,3.1c-0.2,0.2-0.3,0.3,0,0.5c1.8,1.8,3.5,3.6,5.6,5c0.6,0.4,1.3,0.8,2,1.1c0.6,0.3,1.1,0.1,1.2-0.6
		c0.1-0.4,0.1-0.8,0.1-1.2c-0.2-1.3-0.7-2.5-1.4-3.7c-0.7-1.2-1.4-2.3-2.1-3.4c-0.1-0.2-0.2-0.4,0-0.6c0.5-0.4,0.9-0.9,1.4-1.3
		c0.3-0.3,0.4-0.3,0.6,0.1c0.7,1,1.4,2.1,2.1,3.2c0.8,1.2,1.4,2.5,1.8,3.9c0.4,1.4,0.6,2.7,0.1,4.1c-0.7,1.7-2.3,2.5-4,2.1
		c-1.4-0.3-2.5-1-3.6-1.8c-1.8-1.3-3.4-2.9-5-4.5c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.2-0.4-0.2-0.6,0c-1.5,1.3-3,2.7-4.6,4
		c-2.7,2.2-5.4,4.3-8.1,6.4c-0.7,0.5-1.5,1-2.2,1.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.5,0-0.8-0.3-0.5-0.7c0.6-1,1.1-2,1.8-2.9
		c1.6-2.1,3.2-4.2,4.8-6.3c1.7-2.1,3.4-4.1,5.1-6.1c0.2-0.2,0.2-0.4,0-0.6c-1.3-1.3-2.6-2.5-4-3.8c-1-0.9-2.1-1.7-3.3-2.2
		c-0.9-0.4-1.5,0-1.6,0.9c-0.1,1.1,0.3,2,0.7,3c0.8,1.9,1.9,3.7,3.1,5.4c0.2,0.3,0.2,0.5,0,0.7c-0.4,0.5-0.8,0.9-1.2,1.4
		c-0.2,0.3-0.4,0.3-0.6,0c-1.4-1.9-2.6-3.8-3.6-6C1.7,12.9,1.1,11.2,1.2,9.9z M23,9.2c0.2-0.1,0.5-0.2,0.8-0.4c1.6-1.1,2.9-2.4,4-4
		C28,4.6,28.2,4.3,28.3,4c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0-0.4,0.1-0.5,0.2c-1.6,1-2.9,2.3-4.1,3.8
		C23,8,22.8,8.4,22.6,8.8C22.5,9,22.6,9.2,23,9.2z"
      />
      <path
        d="M16,31.2c-0.4,0-0.7-0.1-1-0.4c-1.3-1.4-2.5-2.7-3.8-4.1c-0.2-0.2-0.2-0.4,0.1-0.6c0.5-0.4,0.9-0.8,1.4-1.2
		c0.2-0.2,0.4-0.2,0.6,0c1.2,1.4,2.5,2.7,3.7,4.1c0.3,0.4,0.4,0.9,0.2,1.3C17,30.9,16.6,31.2,16,31.2z"
      />
      <path
        d="M16,0.9c0.3,0.1,0.6,0.2,0.8,0.5c1.1,1.1,2.1,2.2,3.1,3.3c0.2,0.2,0.2,0.3,0,0.5c-0.5,0.5-1,1-1.5,1.4
		c-0.2,0.2-0.3,0.1-0.4,0c-1-1.1-2.1-2.2-3.1-3.3c-0.4-0.4-0.4-0.9-0.3-1.4C14.9,1.4,15.5,1,16,0.9z"
      />
    </g>
  </svg>
);

export default TailorIcon;
