// useToast.js
import { useState, useCallback } from "react";

const useToast = () => {
  const [toasts, setToasts] = useState([]);

  const removeToast = useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const addToast = useCallback(
    (message, type) => {
      const id = Date.now();
      setToasts((prevToasts) => [...prevToasts, { id, message, type }]);
      setTimeout(() => {
        removeToast(id);
      }, 2800); // Удаляем toast через 3 секунды
    },
    [removeToast]
  );

  return { toasts, addToast };
};

export default useToast;
