const BlacksmithIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M27.4,16.8c0-0.5,0-1.1,0-1.6c0-0.2,0-0.2-0.2-0.2c-5.1,0-10.3,0-15.4,0c-0.2,0-0.4-0.1-0.4,0.2c0,0.3,0,0.6,0,0.9
		c0,0.3,0,0.3-0.3,0.3c-2,0-3.9,0-5.9,0c1.1,2.5,3.3,4.3,5.9,4.9c0.1,0,0.2,0.1,0.2,0.2c0,0.6,0,1.2,0,1.8c0,0.2,0,0.2,0.2,0.2
		c1.3,0,2.5,0,3.8,0c-0.8,2-2.3,4-4.1,5.2c-0.5,0-0.9,0-1.4,0c-0.1,0-0.2,0-0.2,0.1c0.1,0.3-0.2,1.1,0.1,1.1c6.4,0,12.8,0,19.2,0
		c0.3,0,0.1-0.8,0.2-1.1c0-0.1,0-0.2-0.2-0.2c-0.5,0-1,0-1.5,0c-2-1.1-3.6-3.1-4.4-5.2c1.4,0,2.8,0,4.2,0c0.2,0,0.2,0,0.2-0.2
		c0-0.5,0-1.1,0-1.6c0-0.2,0.1-0.2,0.2-0.3c0.6-0.2,1.2-0.5,1.8-0.9c0-1,0-2,0-3c-0.6-0.3-1.2-0.5-1.8-0.7
		C27.5,17,27.4,17,27.4,16.8z"
      />
      <path
        d="M12.7,11.5l-0.2,0.1c-0.2,0.1-0.3,0.3-0.2,0.5l0.6,1.3c0.1,0.2,0.3,0.3,0.5,0.2l0.5-0.2c0,0.1,0.1,0.1,0.1,0.2
		c0.1,0.3,0.2,0.3,0.3,0.2c1-0.4,2-0.9,3.1-1.4c0.2-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.3-0.1-0.4l0.6-0.3c0.2-0.1,0.3-0.3,0.2-0.5
		l-0.6-1.3c-0.1-0.2-0.3-0.3-0.5-0.2l0,0c-0.2-1.2-0.6-2.6-1.3-3.9l0.1,0C15.9,5.4,16,5.2,15.9,5l-0.6-1.3c-0.1-0.2-0.3-0.3-0.5-0.2
		l-0.4,0.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.6,0.3-2.8,1.3-3.4,1.6c-0.4,0.1-0.3,0.2-0.2,0.5c0,0.1,0.1,0.3,0.1,0.4L9.9,5.8
		C9.7,5.9,9.6,6.2,9.7,6.4l0.6,1.3c0.1,0.2,0.3,0.3,0.5,0.2l0.1,0C11,8,11.1,8.3,11.2,8.5c0,0.1-0.1,0.1-0.1,0.2
		c-3.1,1.4-6.3,2.8-9.4,4.2C1.3,13,1,13.4,1,13.8v0.7c0,0.4,0.4,0.7,0.8,0.6c3.4-1.5,6.8-3,10.2-4.5C12.3,10.9,12.5,11.2,12.7,11.5z
		"
      />
      <path
        d="M22.6,11.8c0,0.6,0,1.2,0,1.8c0,0.2,8.2-3.2,8.2-3.2s-4.2-0.2-4.3-0.2c0.9-1.3,2.6-3.7,3.4-4.8c-1.5,0.9-3.6,1.9-5.2,2.8
		c0,0,0,0,0,0c0.5-2.1,1-4.1,1.4-6.2c0,0,0,0,0,0c-1.7,2.7-3.4,5.3-5.1,8c0,0,0,0-0.1,0c-0.2-0.7-0.4-1.4-0.5-2.1c0,0,0,0,0,0
		c-0.7,1.9-1.4,3.9-2,5.8c0,0,0,0,0,0C19.8,13.1,21.2,12.4,22.6,11.8z"
      />
    </g>
  </svg>
);

export default BlacksmithIcon;
