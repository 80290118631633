const MenuSound = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M16,16.2c0,3,0,6,0,9c0,1-0.7,1.7-1.7,1.6c-0.4,0-0.8-0.3-1.1-0.5C11.5,24.5,9.8,22.8,8,21c-0.2-0.2-0.3-0.2-0.5-0.2
		c-1.2,0-2.3,0-3.5,0c-1,0-1.7-0.6-1.7-1.7c0-1.9,0-3.9,0-5.8c0-1.1,0.6-1.7,1.7-1.7c1.2,0,2.3,0,3.5,0c0.2,0,0.3-0.1,0.5-0.2
		c1.8-1.8,3.6-3.6,5.4-5.4c0.7-0.7,1.8-0.7,2.4,0.1c0.2,0.3,0.3,0.6,0.3,1C16,10.2,16,13.2,16,16.2z"
      />
      <path
        d="M29.7,16.2c0,4.2-1.5,7.7-4.4,10.7c-0.6,0.6-1.5,0.6-2.2,0.1c-0.6-0.6-0.7-1.5,0-2.2c1.4-1.4,2.4-3,3-4.9
		c1.3-4.5,0.4-8.5-2.7-12c-0.3-0.4-0.7-0.7-0.7-1.2c-0.1-0.7,0.2-1.3,0.8-1.6c0.6-0.3,1.3-0.2,1.8,0.3c1.5,1.5,2.6,3.2,3.4,5.2
		C29.3,12.5,29.7,14.3,29.7,16.2z"
      />
      <path
        d="M23.6,16.2c0,2.5-0.9,4.6-2.6,6.4c-0.6,0.7-1.6,0.7-2.2,0.1c-0.6-0.6-0.6-1.5,0-2.2c2.3-2.5,2.3-6,0-8.5
		c-0.6-0.7-0.6-1.6,0-2.2c0.6-0.6,1.6-0.5,2.2,0.1C22.7,11.6,23.6,13.8,23.6,16.2z"
      />
    </g>
  </svg>
);

export default MenuSound;
