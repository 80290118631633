import React, { useState, useEffect, useRef } from "react";

import "./App.css";
import AuraRng from "./AuraRng.js";
import SlotMachine from "./Casino/SlotMachine.js";
import i18n from "./18n.js";
import { useTranslation } from "react-i18next";
import axios from "axios";
import TopPlayersList from "./TopPlayersList.js";
import { motion, AnimatePresence } from "framer-motion";
import MenuIcon from "./Icons/MenuIcon.js";
import Menu from "./Menu.js";
import ToastNotification from "./Toast/ToastNotification";
import useToast from "./Toast/useToast";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import DucksIcon from "./Icons/DucksIcon.js";
import io from "socket.io-client";
import {
  s_auraBg,
  s_blacksmith,
  s_city,
  s_coins,
  s_gameSwitch,
  s_joeBg,
  s_shopBg,
  s_skeleton,
  s_tailor,
} from "./Sounds.js";

const tg = window.Telegram.WebApp;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
  },
});
//////Отправить ключ в БД бота

const setStorageItem = (key, value) => {
  return new Promise((resolve, reject) => {
    tg.CloudStorage.setItem(key, value, (err) => {
      if (err) {
        return reject(err);
      }
      resolve(value);
    });
  });
};

///////Взять ключ из БД бота

const getStorageItem = (key) => {
  return new Promise((resolve, reject) => {
    tg.CloudStorage.getItem(key, async (err, value) => {
      if (err || !value) {
        try {
          if (key === "language") {
            const language = await setStorageItem("language", i18n.language);
            resolve(language);
          }
        } catch (setError) {
          reject(setError);
        }
        return;
      }
      resolve(value);
    });
  });
};

const socket = io(process.env.REACT_APP_API_URL, {
  withCredentials: true,
  extraHeaders: {
    "Access-Control-Allow-Origin": process.env.REACT_APP_URL,
  },
});

const App = () => {
  const { toasts, addToast } = useToast();
  const [loaded, setLoaded] = useState(false);

  const [currentGame, setCurrentGame] = useState("AuraRng");
  const [localProfile, setLocalProfile] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);

  const handleChangeProfileData = (profile) => {
    setLocalProfile(profile);
  };

  const toggleGame = () => {
    currentGame === "AuraRng"
      ? setCurrentGame("Slots")
      : setCurrentGame("AuraRng");
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [buttonMenuStyle, setButtonMenuStyle] = useState("top-buttons-bg");

  const toggleMenu = () => {
    if (!isSpinning) {
      setIsMenuOpen(!isMenuOpen);
      buttonMenuStyle === "top-buttons-bg"
        ? setButtonMenuStyle("top-buttons-bg top-buttons-bg-open")
        : setButtonMenuStyle("top-buttons-bg");
    }
  };

  ////////////////СМЕНА ЯЗЫКА

  const { t, i18n } = useTranslation();
  const isMobile = useRef(false);

  const changeLanguage = async (language) => {
    i18n.changeLanguage(language);
    await setStorageItem("language", language);
  };

  useEffect(() => {
    const getLanguage = async () => {
      try {
        const storageLanguage = await getStorageItem("language");
        if (i18n.language !== storageLanguage) {
          changeLanguage(storageLanguage);
        }
      } catch (err) {
        changeLanguage("ru");
        console.error("Error setting language in storage:", err);
      }
    };
    getLanguage();

    const isMobileTest = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    isMobile.current = isMobileTest;
  }, []);

  const [gameSwitcherTransformX, setGameSwitcherTransformX] = useState("0px");
  const [gameSwitcherCircleColor, setGameSwitcherCircleColor] =
    useState("#fff");

  const [topListHeader, setTopListHeader] = useState("luckiest");

  useEffect(() => {
    // Включаем или выключаем музыку в зависимости от текущей игры
    if (currentGame === "Slots") {
      console.log("SLOTS");
      s_joeBg.play();
      s_auraBg.stop();
      s_shopBg.stop();
      s_blacksmith.stop();
      s_tailor.stop();
      s_city.stop();
      s_skeleton.stop();
    } else {
      console.log("AURARNG");
      s_joeBg.stop();
      if (nowIsOpen === null) {
        s_auraBg.play();
        return;
      }
      if (nowIsOpen === "shop") {
        s_shopBg.play();
        return;
      }
      if (nowIsOpen === "city") {
        s_city.play();
        return;
      }
      if (nowIsOpen === "blacksmith") {
        s_blacksmith.play();
        return;
      }
      if (nowIsOpen === "tailor") {
        s_tailor.play();
        return;
      }
      if (nowIsOpen === "alchemist") {
        s_skeleton.play();
        return;
      }
    }
    console.log(nowIsOpen);

    // Нет необходимости возвращать функцию очистки, так как это моментальная реакция
  }, [currentGame]);

  const toggleGameSwitcher = () => {
    s_gameSwitch.play();
    if (!isSpinning) {
      gameSwitcherTransformX === "0px"
        ? setGameSwitcherTransformX("32px")
        : setGameSwitcherTransformX("0px");

      gameSwitcherCircleColor === "#a771ff"
        ? setGameSwitcherCircleColor("#fff")
        : setGameSwitcherCircleColor("#a771ff");

      if (currentGame === "AuraRng") {
        setCurrentGame("Slots");
        setTopPlayersText("richestbutton");
        setTopListHeader("richest");
      } else {
        setCombsVisible(false);
        setCurrentGame("AuraRng");
        setTopPlayersText("topaurasbutton");
        setTopListHeader("luckiest");
      }
      if (topPlayersButtonStyle !== "top-buttons-bg") {
        setTopPlayersButtonStyle("top-buttons-bg");
      }
    }
  };

  const [topPlayersButtonStyle, setTopPlayersButtonStyle] =
    useState("top-buttons-bg");

  const [topPlayersText, setTopPlayersText] = useState("topaurasbutton");

  const toggleTopPlayers = () => {
    s_gameSwitch.play();
    setTopPlayersWindow(!topPlayersWindow);
    topPlayersButtonStyle === "top-buttons-bg"
      ? setTopPlayersButtonStyle("top-buttons-bg top-buttons-bg-open")
      : setTopPlayersButtonStyle("top-buttons-bg");
  };
  const [topPlayersWindow, setTopPlayersWindow] = useState(false);

  /////////////////////Кнопка обновить топ игроков

  const [isCooldown, setIsCooldown] = useState(false);

  const handleButtonRefresh = () => {
    if (isCooldown) return;

    currentGame === "AuraRng" ? fetchLuckiestPlayers() : fetchRichestPlayers();

    setIsCooldown(true);

    // Set cooldown for 30 seconds
    setTimeout(() => {
      setIsCooldown(false);
    }, 30000);
  };

  const [luckiestList, setLuckiestList] = useState([]);
  const [richestList, setRichestList] = useState([]);

  const fetchLuckiestPlayers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/players`,
        {
          params: {
            sort: ["mainAuraRarity:desc"],
            "pagination[start]": 0,
            "pagination[limit]": 10,
          },
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      setLuckiestList(response.data.data);
    } catch (error) {
      console.error("Error fetching top players:", error);
      setLuckiestList([]); // Set to empty array in case of error
    }
  };
  const fetchRichestPlayers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/players`,
        {
          params: {
            sort: ["ducks:desc"],
            "pagination[start]": 0,
            "pagination[limit]": 10,
          },
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      setRichestList(response.data.data);
    } catch (error) {
      console.error("Error fetching top players:", error);
      setRichestList([]); // Set to empty array in case of error
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchLuckiestPlayers();
      await fetchRichestPlayers();

      if (tg) {
        tg.isVerticalSwipesEnabled = false;
      }
    };
    fetch();
  }, []);

  const [combsVisible, setCombsVisible] = useState(false);

  const toggleCombs = () => {
    s_gameSwitch.play();
    if (!isSpinning) {
      setCombsVisible(!combsVisible);
    }
  };

  const handleNameChange = async (newName) => {
    try {
      const response = await axiosInstance.put(
        `api/players/${localProfile.id}`,
        {
          data: {
            firstName: newName,
            ducks: localProfile.attributes.ducks - 100000,
          },
        }
      );
      console.log("Name changed successfully:", response.data);

      setLocalProfile((prevProfile) => ({
        ...prevProfile,
        attributes: {
          ...prevProfile.attributes,
          firstName: newName,
          ducks: localProfile.attributes.ducks - 100000,
        },
      }));
    } catch (error) {
      console.error("Error changing name:", error);
    }
  };
  const handleDescriptionChange = async (description) => {
    console.log(description);
    try {
      const response = await axiosInstance.put(
        `api/players/${localProfile.id}`,
        {
          data: {
            description: description,
            ducks: localProfile.attributes.ducks - 9900,
          },
        }
      );
      console.log("Name changed successfully:", response.data);

      setLocalProfile((prevProfile) => ({
        ...prevProfile,
        attributes: {
          ...prevProfile.attributes,
          description: description,
          ducks: localProfile.attributes.ducks - 9900,
        },
      }));
    } catch (error) {
      console.error("Error changing name:", error);
    }
  };

  const handleDucksSend = (id, count) => {
    try {
      // Отправка данных через socket
      socket.emit("ducksSend", {
        senderId: localProfile?.id, // Используем безопасную навигацию
        receiverId: id,
        count: count,
      });

      // Обработчик успешной отправки
      const ducksSendSuccess = (data) => {
        if (!localProfile || !localProfile.attributes) {
          console.error("localProfile или attributes не определены");
          return;
        }

        const { tgId, count, senderId } = data;
        const newDucks = data.newDucks;
        const message = `${localProfile.attributes.firstName} [id: ${senderId}] отправил тебе ${count} DUCK$!`;

        // Отправляем уведомление через API
        axios
          .post(process.env.REACT_APP_TGBOT_URL, {
            tgId: tgId,
            message: message,
          })
          .catch((err) => console.error("Ошибка отправки сообщения:", err));

        // Обновляем профиль
        setLocalProfile((prevProfile) => ({
          ...prevProfile,
          attributes: {
            ...prevProfile.attributes,
            ducks: newDucks,
          },
        }));

        // Показываем уведомление
        addToast(t("ducks sent"), "success");
      };

      // Обработчик ошибки отправки
      const ducksSendError = (data) => {
        console.error("ducksSendError", data);
        addToast(t(data.message), "error");
      };

      // Обработчик изменения баланса уток
      const ducksChangeSuccess = (data) => {
        console.log("ducksChange Success");
        const { newDucks } = data;

        setLocalProfile((prevProfile) => ({
          ...prevProfile,
          attributes: {
            ...prevProfile.attributes,
            ducks: newDucks,
          },
        }));
      };

      // Устанавливаем одноразовые обработчики для сокет-событий
      socket.once("ducksSendSuccess", ducksSendSuccess);
      socket.once("ducksSendError", ducksSendError);
      socket.once("ducksChangeSuccess", ducksChangeSuccess);
    } catch (error) {
      console.error("ERROR DUCKS SEND", error);
    }
  };

  const handleFarm = (count) => {
    try {
      socket.emit("ducksChange", {
        playerId: localProfile.id,
        count: count,
      });
      socket.once("ducksChangeSuccess", (data) => {
        const newDucks = data.newDucks;
        s_coins.play();

        setLocalProfile((prevProfile) => ({
          ...prevProfile,
          attributes: {
            ...prevProfile.attributes,
            ducks: newDucks,
          },
        }));
      });

      console.log("HNDLE FARm");
    } catch (error) {
      console.log("ERROR DUCKS FARM ", error);
    }
  };

  const handleAuraLoaded = (current) => {
    setTimeout(() => {}, 1000);
    setLoaded(current);
  };

  const [nowIsOpen, setNowIsOpen] = useState(null);

  const handleNowOpen = (current) => {
    setNowIsOpen(current);
  };

  const handleAurasUpdate = (addAura = null, deleteAura = null) => {
    try {
      // Формируем данные для события в зависимости от наличия addAura и deleteAura
      const data = {
        playerId: localProfile.id,
      };

      if (addAura) {
        data.addAura = {
          title: addAura.title,
          probability: addAura.probability,
          quantity: addAura.quantity || 1,
        };
      }

      if (deleteAura) {
        data.deleteAura = {
          title: deleteAura.title,
          quantity: deleteAura.quantity || 1,
        };
      }

      // Отправляем событие на сервер с одним или обоими параметрами (добавление и/или удаление)
      socket.emit("aurasUpdate", data);
    } catch (error) {
      console.log("ERROR UPDATE AURAS ", error);
    }
  };

  return (
    <>
      <AnimatePresence>
        {!loaded && (
          <motion.div
            initial={{ opacity: 1 }}
            animation={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 9999,
              backgroundColor: "black",
            }}
          >
            <Player
              autoplay
              loop
              src="https://lottie.host/48939bc0-b4d9-44bb-8b3d-3e4185dea668/pHjB06vwuE.json"
              style={{ height: "150px", width: "150px" }}
            ></Player>
            {isMobile.current && (
              <div
                style={{
                  color: "grey",
                  maxWidth: "300px",
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                {t(
                  "Все персонажи и предметы в этой игре вымышлены, даже те, которые основаны на реальных."
                )}
              </div>
            )}

            {!isMobile.current && (
              <>
                <div
                  style={{
                    color: "grey",
                    maxWidth: "300px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Заходи через телеграм!
                </div>
                <button>
                  <a href="https://t.me/aura_rng_bot">Перейти в бота</a>
                </button>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {isMobile.current && (
        <div style={{ opacity: loaded ? 1 : 0 }}>
          <header>
            <div
              style={{
                display: "flex",
                opacity: isMenuOpen ? 0.2 : 1,
                pointerEvents: isMenuOpen ? "none" : "auto",
                width: "80vw",
                justifyContent: "space-between",
                alignItems: "center",
                transition: "0.1s ease",
              }}
            >
              <div
                onClick={toggleGameSwitcher}
                className="toggle-game-container"
              >
                <div
                  style={{
                    backgroundColor: `${gameSwitcherCircleColor}`,
                    transform: `translateX(${gameSwitcherTransformX})`,
                  }}
                  className="toggle-game-circle"
                ></div>
              </div>
              <div
                onClick={toggleCombs}
                style={{ display: currentGame === "AuraRng" ? "none" : "flex" }}
                className="top-buttons-bg"
              >
                ?
              </div>
              <motion.div
                key={localProfile ? localProfile.attributes.ducks : ""}
                initial={{ scale: 1.5 }}
                animate={{ scale: 1 }}
                style={{
                  display: currentGame === "AuraRng" ? "flex" : "none",
                  fontSize: "14px",
                  color: "#dbdbdb",
                }}
              >
                {localProfile && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {localProfile.attributes.ducks}
                    <DucksIcon
                      style={{ marginLeft: "5px" }}
                      size={"16px"}
                    ></DucksIcon>
                  </div>
                )}
              </motion.div>
              <div onClick={toggleTopPlayers} className={topPlayersButtonStyle}>
                <button className="top-players">{t(topPlayersText)}</button>
              </div>
            </div>
            <div onClick={toggleMenu} className={buttonMenuStyle}>
              <MenuIcon width={"16px"} style={{ color: "#666" }}></MenuIcon>
            </div>
          </header>

          <div className="app">
            {topPlayersWindow && (
              <>
                <motion.ul
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="top-players-list"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="top10-header">{t(topListHeader)}</div>{" "}
                    <button
                      onClick={handleButtonRefresh}
                      disabled={isCooldown}
                      className="refresh-button-container"
                    >
                      <span className="material-symbols-outlined refresh-button">
                        sync
                      </span>
                    </button>
                  </div>
                  <TopPlayersList
                    type={currentGame}
                    topPlayers={
                      currentGame === "AuraRng" ? luckiestList : richestList
                    }
                    tgId={localProfile.attributes.tgId}
                  ></TopPlayersList>
                </motion.ul>
                <div
                  onClick={() => toggleTopPlayers()}
                  className="black-screen"
                ></div>
              </>
            )}
            <div
              style={{
                display:
                  !isMenuOpen && currentGame === "AuraRng" ? "initial" : "none",
                backgroundColor: "#141414",
                height: "100%",
              }}
            >
              <AuraRng
                socket={socket}
                t={t}
                farm={handleFarm}
                localProfile={localProfile}
                sendLocalProfileData={handleChangeProfileData}
                sendSpinning={(value) => {
                  setIsSpinning(value);
                }}
                toggleFunction={toggleGame}
                updateAuras={handleAurasUpdate}
                sendNowOpen={handleNowOpen}
                isAuraLoaded={handleAuraLoaded}
              ></AuraRng>
            </div>
            {combsVisible && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-combs"
                  width={"100%"}
                >
                  <img alt="combo" width={"100%"} src="/combs.webp"></img>
                </motion.div>
                <div
                  onClick={() => toggleCombs()}
                  className="black-screen"
                ></div>
              </>
            )}

            {localProfile && (
              <div
                style={{
                  height: "100%",
                  display:
                    !isMenuOpen && currentGame === "Slots" ? "initial" : "none",
                  backgroundColor: "#141414",
                }}
              >
                <SlotMachine
                  socket={socket}
                  farm={handleFarm}
                  t={t}
                  sendSpinning={(value) => {
                    setIsSpinning(value);
                  }}
                  localProfile={localProfile}
                  sendLocalProfileData={handleChangeProfileData}
                  toggleFunction={toggleGame}
                ></SlotMachine>
              </div>
            )}
            {isMenuOpen && (
              <div>
                <Menu
                  localProfile={localProfile}
                  onChangeName={handleNameChange}
                  onChangeDescription={handleDescriptionChange}
                  onSendDucks={handleDucksSend}
                  setStorageItem={setStorageItem}
                ></Menu>
                <div
                  onClick={() => toggleMenu()}
                  className="black-screen"
                ></div>
              </div>
            )}
          </div>
          <ToastNotification toasts={toasts} />
        </div>
      )}
    </>
  );
};

export default App;
