const DucksIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
      .st0 { fill: #F8D423; }
      .st1 { fill: #ED7745; }
      .st2 { fill: #010318; }
    `}
    </style>
    <g>
      <g>
        <circle className="st0" cx="16" cy="16" r="16" />
      </g>
      <g>
        <path
          className="st1"
          d="M16,28.6C9.1,28.6,3.4,22.9,3.4,16C3.4,9.1,9.1,3.4,16,3.4c6.9,0,12.6,5.6,12.6,12.6
			C28.6,22.9,22.9,28.6,16,28.6z M16,4.5C9.6,4.5,4.5,9.6,4.5,16S9.6,27.5,16,27.5S27.5,22.4,27.5,16S22.4,4.5,16,4.5z"
        />
      </g>
      <g>
        <path
          className="st2"
          d="M21.5,13.6v-1.9h-1.9V9.9h-3.3V7.7h-1.7v2.2h-4.4V23h4.4v2h1.7v-2h3.3v-1.9h1.9v-1.9h1.9v-5.6H21.5z
			 M13.5,21.1v-9.4h1.2v9.4H13.5z M19.6,19.2h-1.9v1.9h-1.4v-9.4h1.4v1.9h1.9V19.2z"
        />
      </g>
    </g>
  </svg>
);

export default DucksIcon;
