import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./App.css";
import { getItemByName } from "./Functions";

const potions = [
  "Small Lucky Potion",
  "Small Speed Potion",
  "Small Lucky Potion II",
  "Small Speed Potion II",
];

const PotionContainer = ({ addPotionToInventory, handR }) => {
  const [potionVisible, setPotionVisible] = useState(false);
  const [potionPosition, setPotionPosition] = useState({ top: 0, left: 0 });
  const [currentPotion, setCurrentPotion] = useState(null);
  const potionRef = useRef();

  useEffect(() => {
    const generatePotion = async () => {
      const container = potionRef.current.parentElement;
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      // Случайные координаты внутри контейнера
      const top = Math.random() * (containerHeight - 70); // 70px - высота зелья
      const left = Math.random() * (containerWidth - 70);

      let randomPotion = null;

      // Выбираем случайное зелье
      if (handR && handR.title === "Lucky glove") {
        randomPotion = potions[Math.floor(Math.random() * potions.length)];
      } else {
        randomPotion = potions[Math.floor(Math.random() * 2)];
      }
      const potionInfo = await getItemByName(randomPotion);
      setCurrentPotion(potionInfo);
      console.log(potionInfo);
      setPotionPosition({ top, left });
      setPotionVisible(true);

      // Убираем зелье через 20 секунд, если его не кликнули
      setTimeout(() => setPotionVisible(false), 20000);
    };

    // Случайный интервал (1–2 минуты)
    const min = 0.5;
    const max = 3;

    const interval = Math.random() * (max - min) * 60000 + min * 60000;

    const timer = setTimeout(generatePotion, interval);

    return () => clearTimeout(timer);
  }, [potionVisible]); // Перезапуск после исчезновения зелья

  const handlePotionClick = () => {
    setPotionVisible(false); // Убираем зелье
    if (currentPotion) {
      addPotionToInventory(currentPotion); // Передаем данные текущего зелья
    }
  };

  return (
    <div className="potion-container" ref={potionRef}>
      <AnimatePresence>
        {potionVisible && currentPotion && (
          <motion.img
            src={currentPotion.miniimg}
            alt={currentPotion.title}
            className="potion"
            style={{
              zIndex: 3,
              position: "absolute",
              top: potionPosition.top,
              left: potionPosition.left,
              width: "70px",
              height: "70px",
            }}
            // Анимация появления
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: [1, 1.2, 1], opacity: 1 }}
            transition={{
              scale: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 1.5, // Пульсация
              },
              opacity: { duration: 0.5 },
            }}
            // Анимация исчезновения
            exit={{ scale: 0, opacity: 0 }}
            onClick={handlePotionClick}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default PotionContainer;
