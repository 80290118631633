const alchemistItems = [
  {
    title: "Small Lucky Potion",
    color: "#3e7f33",
    img: "potion-images/luck1x1.webp",
    miniimg: "potion-images/120px/luck1x1.webp",
    needItems: [
      { title: "Stone", type: "aura", quantity: 1 },
      { title: "Malachite", type: "aura", quantity: 5 },
      { title: "Tourmaline", type: "aura", quantity: 1 },
    ],
    cost: 0,
    type: "potion",
  },
  {
    title: "Small Lucky Potion II",
    color: "#3e7f33",
    img: "potion-images/luck1x2.webp",
    miniimg: "potion-images/120px/luck1x2.webp",
    needItems: [
      { title: "Small Lucky Potion", type: "potion", quantity: 5 },
      { title: "Lucky", type: "aura", quantity: 5 },
      { title: "Malfurios", type: "aura", quantity: 2 },
    ],
    cost: 1000,
    type: "potion",
  },
  {
    title: "Small Lucky Potion III",
    color: "#3e7f33",
    img: "potion-images/luck1x3.webp",
    miniimg: "potion-images/120px/luck1x3.webp",
    needItems: [
      { title: "Small Lucky Potion II", type: "potion", quantity: 5 },
      { title: "Jelly yellow", type: "aura", quantity: 5 },
      { title: "Eye", type: "aura", quantity: 1 },
    ],
    cost: 10000,
    type: "potion",
  },
  {
    title: "Medium Lucky Potion",
    color: "#3e7f33",
    img: "potion-images/luck2x1.webp",
    miniimg: "potion-images/120px/luck2x1.webp",
    needItems: [
      { title: "Purple hat", type: "aura", quantity: 1 },
      { title: "Pink hat", type: "aura", quantity: 1 },
      { title: "Saitamo", type: "aura", quantity: 1 },
    ],
    cost: 0,
    type: "potion",
  },
  {
    title: "Medium Lucky Potion II",
    color: "#3e7f33",
    img: "potion-images/luck2x2.webp",
    miniimg: "potion-images/120px/luck2x2.webp",
    needItems: [
      { title: "Medium Lucky Potion", type: "potion", quantity: 5 },
      { title: "Jokey", type: "aura", quantity: 5 },
      { title: "Kyle Broslavski", type: "aura", quantity: 1 },
      { title: "Sengoku", type: "aura", quantity: 1 },
    ],
    cost: 5000,
    type: "potion",
  },
  {
    title: "Medium Lucky Potion III",
    color: "#3e7f33",
    img: "potion-images/luck2x3.webp",
    miniimg: "potion-images/120px/luck2x3.webp",
    needItems: [
      { title: "Medium Lucky Potion II", type: "potion", quantity: 5 },
      { title: "Wine", type: "aura", quantity: 5 },
      { title: "Doll", type: "aura", quantity: 1 },
      { title: "Basket-Ball", type: "aura", quantity: 1 },
    ],
    cost: 25000,
    type: "potion",
  },
  {
    title: "Large Lucky Potion",
    color: "#3e7f33",
    img: "potion-images/luck3x1.webp",
    miniimg: "potion-images/120px/luck3x1.webp",
    needItems: [
      { title: "P-I-M-P", type: "aura", quantity: 1 },
      { title: "Omen man", type: "aura", quantity: 1 },
    ],
    cost: 0,
    type: "potion",
  },
  {
    title: "Large Lucky Potion II",
    color: "#3e7f33",
    img: "potion-images/luck3x2.webp",
    miniimg: "potion-images/120px/luck3x2.webp",
    needItems: [
      { title: "Large Lucky Potion", type: "potion", quantity: 5 },
      { title: "Hidden-Power", type: "aura", quantity: 5 },
      { title: "Doomba", type: "aura", quantity: 1 },
      { title: "2-Mew", type: "aura", quantity: 1 },
    ],
    cost: 5000,
    type: "potion",
  },
  {
    title: "Large Lucky Potion III",
    color: "#3e7f33",
    img: "potion-images/luck3x3.webp",
    miniimg: "potion-images/120px/luck3x3.webp",
    needItems: [
      { title: "Large Lucky Potion II", type: "potion", quantity: 5 },
      { title: "Whirl", type: "aura", quantity: 5 },
      { title: "Skull", type: "aura", quantity: 1 },
      { title: "Purple-Eye", type: "aura", quantity: 1 },
    ],
    cost: 25000,
    type: "potion",
  },

  {
    title: "Small Speed Potion",
    color: "#1a68c8",
    img: "potion-images/speed1x1.webp",
    miniimg: "potion-images/120px/speed1x1.webp",
    needItems: [
      { title: "Mossy", type: "aura", quantity: 1 },
      { title: "Grogus", type: "aura", quantity: 5 },
      { title: "NEFT", type: "aura", quantity: 1 },
    ],
    cost: 0,
    type: "potion",
  },
  {
    title: "Small Speed Potion II",
    color: "#1a68c8",
    img: "potion-images/speed1x2.webp",
    miniimg: "potion-images/120px/speed1x2.webp",
    needItems: [
      { title: "Small Speed Potion", type: "potion", quantity: 5 },
      { title: "Previ", type: "aura", quantity: 5 },
      { title: "Makina", type: "aura", quantity: 2 },
    ],
    cost: 1000,
    type: "potion",
  },
  {
    title: "Small Speed Potion III",
    color: "#1a68c8",
    img: "potion-images/speed1x3.webp",
    miniimg: "potion-images/120px/speed1x3.webp",
    needItems: [
      { title: "Small Speed Potion II", type: "potion", quantity: 5 },
      { title: "Ken Ye", type: "aura", quantity: 5 },
      { title: "Jeina", type: "aura", quantity: 1 },
    ],
    cost: 10000,
    type: "potion",
  },
  {
    title: "Medium Speed Potion",
    color: "#1a68c8",
    img: "potion-images/speed2x1.webp",
    miniimg: "potion-images/120px/speed2x1.webp",
    needItems: [
      { title: "Pohacontas", type: "aura", quantity: 1 },
      { title: "J-Woo", type: "aura", quantity: 1 },
      { title: "Smiley—R0CK", type: "aura", quantity: 1 },
    ],
    cost: 0,
    type: "potion",
  },
  {
    title: "Medium Speed Potion II",
    color: "#1a68c8",
    img: "potion-images/speed2x2.webp",
    miniimg: "potion-images/120px/speed2x2.webp",
    needItems: [
      { title: "Medium Speed Potion", type: "potion", quantity: 5 },
      { title: "Sugar", type: "aura", quantity: 5 },
      { title: "Kahosi", type: "aura", quantity: 1 },
      { title: "Peace", type: "aura", quantity: 1 },
    ],
    cost: 5000,
    type: "potion",
  },
  {
    title: "Medium Speed Potion III",
    color: "#1a68c8",
    img: "potion-images/speed2x3.webp",
    miniimg: "potion-images/120px/speed2x3.webp",
    needItems: [
      { title: "Medium Speed Potion II", type: "potion", quantity: 5 },
      { title: "Mike Spiegel", type: "aura", quantity: 5 },
      { title: "Gulbadan", type: "aura", quantity: 1 },
      { title: "Kyonin", type: "aura", quantity: 1 },
    ],
    cost: 25000,
    type: "potion",
  },
  {
    title: "Large Speed Potion",
    color: "#1a68c8",
    img: "potion-images/speed3x1.webp",
    miniimg: "potion-images/120px/speed3x1.webp",
    needItems: [
      { title: "Gatsu", type: "aura", quantity: 1 },
      { title: "Agara", type: "aura", quantity: 1 },
    ],
    cost: 0,
    type: "potion",
  },
  {
    title: "Large Speed Potion II",
    color: "#1a68c8",
    img: "potion-images/speed3x2.webp",
    miniimg: "potion-images/120px/speed3x2.webp",
    needItems: [
      { title: "Large Speed Potion", type: "potion", quantity: 5 },
      { title: "Space boy", type: "aura", quantity: 5 },
      { title: "Emily", type: "aura", quantity: 1 },
      { title: "Rat", type: "aura", quantity: 1 },
    ],
    cost: 5000,
    type: "potion",
  },
  {
    title: "Large Speed Potion III",
    color: "#1a68c8",
    img: "potion-images/speed3x3.webp",
    miniimg: "potion-images/120px/speed3x3.webp",
    needItems: [
      { title: "Large Speed Potion II", type: "potion", quantity: 5 },
      { title: "Excalibur", type: "aura", quantity: 5 },
      { title: "Treasure", type: "aura", quantity: 1 },
      { title: "Cotton-Candy", type: "aura", quantity: 1 },
    ],
    cost: 25000,
    type: "potion",
  },
];
export default alchemistItems;
