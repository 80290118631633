export function pop(e) {
  let amount = 10;

  if (e.clientX === 0 && e.clientY === 0) {
    const bbox = e.target.getBoundingClientRect();
    const x = bbox.left + bbox.width / 2;
    const y = bbox.top + bbox.height / 2;
    for (let i = 0; i < amount; i++) {
      createParticle(x, y);
    }
  } else {
    for (let i = 0; i < amount; i++) {
      createParticle(e.clientX, e.clientY);
    }
  }
}

function createParticle(x, y) {
  const particle = document.createElement("particle");
  document.body.appendChild(particle);
  let width = Math.random() * 5 + 4;
  let height = width;
  let destinationX = (Math.random() - 0.5) * 300;
  let destinationY = (Math.random() - 0.5) * 300;
  let rotation = 0;

  var color = `hsl(${Math.random() * 50 + 200}, 70%, 50%)`;
  particle.style.background = color;
  particle.style.borderRadius = "50%";
  particle.style.zIndex = 10;

  particle.style.width = `${width}px`;
  particle.style.height = `${height}px`;
  const animation = particle.animate(
    [
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
        opacity: 1,
      },
      {
        transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${
          y + destinationY
        }px) rotate(${rotation}deg)`,
        opacity: 0,
      },
    ],
    {
      duration: Math.random() * 1000 + 2000,
      easing: "cubic-bezier(0.000, 0.970, 0.860, 0.990)",
    }
  );
  animation.onfinish = removeParticle;
}

function removeParticle(e) {
  e.srcElement.effect.target.remove();
}

export function Ripple(e) {
  let amount = 1;

  if (e.clientX === 0 && e.clientY === 0) {
    const bbox = e.target.getBoundingClientRect();
    const x = bbox.left + bbox.width / 2;
    const y = bbox.top + bbox.height / 2;
    for (let i = 0; i < amount; i++) {
      createRipple(x, y);
    }
  } else {
    for (let i = 0; i < amount; i++) {
      createRipple(e.clientX, e.clientY);
    }
  }
}

function createRipple(x, y) {
  const particle = document.createElement("particle");
  document.body.appendChild(particle);
  let width = 150;
  let height = width;

  var color = `#fff`;
  particle.style.background = color;
  particle.style.borderRadius = "50%";
  particle.style.zIndex = 5;

  particle.style.width = `${width}px`;
  particle.style.height = `${height}px`;
  const animation = particle.animate(
    [
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) scale(0)`,
        opacity: 1,
      },
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) scale(1)`,
        opacity: 0,
      },
    ],
    {
      duration: 200,
      easing: "cubic-bezier(0.000, 0.970, 0.860, 0.990)",
    }
  );
  animation.onfinish = removeRipple;
}

function removeRipple(e) {
  e.srcElement.effect.target.remove();
}

export function PopCoins(e, value) {
  let amount = Math.round(Math.sqrt(value));
  if (amount > 30) {
    amount = 30;
  }

  if (e.clientX === 0 && e.clientY === 0) {
    const bbox = e.target.getBoundingClientRect();
    const x = bbox.left + bbox.width / 2;
    const y = bbox.top + bbox.height / 2;
    for (let i = 0; i < amount; i++) {
      createCoins(x, y);
    }
  } else {
    for (let i = 0; i < amount; i++) {
      createCoins(e.clientX, e.clientY);
    }
  }
}

function createCoins(x, y) {
  const particle = document.createElement("particle");
  document.body.appendChild(particle);
  let width = Math.random() * 5 + 10;
  let height = width;
  let destinationX = (Math.random() - 0.5) * 90;
  let destinationY = Math.random() * -500;
  let rotation = 0;

  var color = `hsl(${Math.random() * 20 + 40}, 80%, 50%)`;
  particle.style.background = color;
  particle.style.borderRadius = "50%";
  particle.style.zIndex = 5;

  particle.style.width = `${width}px`;
  particle.style.height = `${height}px`;
  const animation = particle.animate(
    [
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
        opacity: 1,
      },
      {
        transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${
          y + destinationY
        }px) rotate(${rotation}deg)`,
        opacity: 0,
      },
    ],
    {
      duration: Math.random() * 1000 + 2000,
      easing: "cubic-bezier(0.000, 0.970, 0.860, 0.990)",
    }
  );
  animation.onfinish = removeCoins;
}

function removeCoins(e) {
  e.srcElement.effect.target.remove();
}
