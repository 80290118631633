import React from "react";
import PlayerListItem from "./PlayerListItem";
import "./App.css";

const TopPlayersList = ({ topPlayers, tgId, type }) => {
  return (
    <div
      style={{
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      {topPlayers.map((player, index) => (
        <PlayerListItem
          type={type}
          key={index}
          player={player}
          index={index}
          tgId={tgId}
        />
      ))}
    </div>
  );
};

export default TopPlayersList;
