import { useState, useEffect } from "react";
import ArrowLeft from "./Icons/MenuArrowLeft";
import ArrowRight from "./Icons/MenuArrowRight";
import "./Menu.css";
import ToastNotification from "./Toast/ToastNotification";
import useToast from "./Toast/useToast";
import { motion } from "framer-motion";
import SellIcon from "./Icons/SellIcon.js";
import { useTranslation } from "react-i18next";
import { Howler } from "howler";
import MenuSound from "./Icons/MenuSound.js";
import { s_gameSwitch } from "./Sounds.js";

const Menu = ({
  onChangeName,
  onChangeDescription,
  onSendDucks,
  localProfile,
  setStorageItem,
}) => {
  const { toasts, addToast } = useToast();

  const [menuPage, setMenuPage] = useState("main");

  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [recieverId, setRecieverId] = useState("");
  const [ducksCount, setDucksCount] = useState();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    s_gameSwitch.play();
  }, [menuPage]);

  const changeLanguage = async (language) => {
    i18n.changeLanguage(language);
    await setStorageItem("language", language);
  };
  const toggleLanguage = () => {
    s_gameSwitch.play();
    i18n.language === "ru" ? changeLanguage("en") : changeLanguage("ru");
  };
  const [isMuted, setIsMuted] = useState(false);

  const toggleSound = () => {
    s_gameSwitch.play();
    setIsMuted(!isMuted); // Меняем состояние
    Howler.mute(!isMuted); // Включаем/выключаем звуки
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    if (value.length <= 30) {
      setNewDescription(e.target.value);
    }
  };

  const handleSubmitName = (e) => {
    e.preventDefault();
    if (localProfile.attributes.ducks < 100000) {
      addToast(t("not enough ducks"), "error");
      return;
    }
    onChangeName(newName);
    setMenuPage("main");
    addToast(t("name changed") + newName, "success");
  };

  const handleSubmitDescription = (e) => {
    e.preventDefault();
    if (localProfile.attributes.ducks < 9900) {
      addToast(t("not enough ducks"), "error");
      return;
    }
    onChangeDescription(newDescription);
    setMenuPage("main");
    addToast(t("description changed") + newName, "success");
  };

  const handleSubmitDucks = (e) => {
    e.preventDefault();
    if (parseInt(recieverId) !== localProfile.id) {
      onSendDucks(parseInt(recieverId), parseInt(ducksCount));
      setMenuPage("main");
    } else {
      addToast(t("same id"), "error");
    }
  };

  return (
    <div className="menu-container">
      <div className="menu-header">
        <ArrowLeft
          style={{
            opacity: menuPage === "main" ? 0 : 1,
            pointerEvents: menuPage === "main" ? "none" : "auto",
          }}
          onClick={() => setMenuPage("main")}
          size="16px"
        ></ArrowLeft>
        <div>ID: {localProfile.id}</div>
      </div>
      {menuPage === "main" && (
        <motion.div
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          style={{ display: menuPage === "main" ? "flex" : "none" }}
          className="menu-items"
        >
          <li onClick={toggleSound} className="menu-item">
            <div>{isMuted ? t("sound on") : t("sound off")}</div>
            <MenuSound size="16px"></MenuSound>
          </li>
          <li onClick={() => setMenuPage("statistics")} className="menu-item">
            <div>{t("Statistics")}</div>
            <ArrowRight size="16px"></ArrowRight>
          </li>
          <li onClick={() => setMenuPage("changeName")} className="menu-item">
            <div>{t("name change")}</div>
            <ArrowRight size="16px"></ArrowRight>
          </li>
          <li
            onClick={() => setMenuPage("changeDescription")}
            className="menu-item"
          >
            <div>{t("setDescription")}</div>
            <ArrowRight size="16px"></ArrowRight>
          </li>
          <li onClick={() => setMenuPage("sendDucks")} className="menu-item">
            <div>{t("send ducks")}</div>
            <ArrowRight size="16px"></ArrowRight>
          </li>
          <li onClick={() => toggleLanguage()} className="menu-item">
            <div>{t("changeLang")}</div>
            <SellIcon size="16px"></SellIcon>
          </li>
        </motion.div>
      )}
      {menuPage === "statistics" && (
        <motion.div
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          className="statistics-container"
        >
          <div className="statistics-item-container">
            <div className="statistics-item">{t("Best combo: ")}</div>
            <div className="statistics-item">
              {localProfile.attributes.bestCombo}
            </div>
          </div>
          <div className="statistics-item-container">
            <div className="statistics-item">{t("Spins: ")}</div>
            <div className="statistics-item">
              {localProfile.attributes.spins}
            </div>
          </div>
        </motion.div>
      )}
      {menuPage === "changeName" && (
        <motion.form
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          onSubmit={handleSubmitName}
          className="change-name-container"
        >
          <input
            onChange={(e) => setNewName(e.target.value)}
            required={true}
            type="text"
            id="newName"
            name="newName"
            maxLength={16}
            minLength={2}
            className="name-input"
            placeholder={localProfile.attributes.firstName}
          ></input>
          <div className="change-name-description">
            {t("cost") + ": 100000 DUCK$"}
          </div>
          <button className="menu-button">{t("name change")}</button>
        </motion.form>
      )}
      {menuPage === "changeDescription" && (
        <motion.form
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          onSubmit={handleSubmitDescription}
          className="change-name-container"
        >
          <input
            onChange={handleDescriptionChange}
            value={newDescription}
            required={true}
            type="text"
            id="newDescription"
            name="newDescription"
            minLength={1}
            className="name-input"
            placeholder={localProfile.attributes.description}
          ></input>
          <div className="change-name-description">
            {t("cost") + ": 9900 DUCK$"}
          </div>
          <button className="menu-button">{t("setDescription")}</button>
        </motion.form>
      )}
      {menuPage === "sendDucks" && (
        <motion.form
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          onSubmit={handleSubmitDucks}
          className="change-name-container"
        >
          <input
            onChange={(e) => setRecieverId(e.target.value)}
            required={true}
            type="number"
            id="recieverId"
            name="recieverId"
            maxLength={20}
            step="1"
            minLength={5}
            className="name-input"
            placeholder={t("RecipientID")}
          ></input>
          <input
            onChange={(e) => setDucksCount(e.target.value)}
            required={true}
            step="1"
            type="number"
            id="ducks"
            name="ducks"
            maxLength={20}
            min={100}
            max={localProfile.attributes.ducks}
            className="name-input"
            placeholder={t("amount")}
          ></input>
          <div className="change-name-description">{t("know id")}</div>
          <button className="menu-button">{t("send")}</button>
        </motion.form>
      )}

      <ToastNotification toasts={toasts} />
    </div>
  );
};
export default Menu;
