const AurasIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <circle cx="4.7" cy="13.2" r="3.1" />
      <circle cx="15.7" cy="5" r="3.1" />
      <circle cx="26.9" cy="12.9" r="3.1" />
      <path
        d="M27.4,18.2l-4.1-2.9c-0.7-0.5-1-1.4-0.8-2.2L24,8.3c0.6-1.9-1.6-3.3-3.1-2.2l-4,3c-0.7,0.5-1.6,0.5-2.3,0l-4.1-2.9
		c-1.6-1.1-3.7,0.5-3,2.3l1.6,4.7c0.3,0.8,0,1.7-0.7,2.2l-4,3c-1.5,1.2-0.7,3.6,1.2,3.6l5-0.1c0.9,0,1.6,0.5,1.9,1.3l1.6,4.7
		c0.6,1.8,3.2,1.8,3.8-0.1l1.5-4.8c0.3-0.8,1-1.4,1.9-1.4l5-0.1C28.2,21.8,29,19.3,27.4,18.2z M18.2,20.5c-2.1,1.4-4.9,0.8-6.3-1.2
		c-1.4-2.1-0.8-4.9,1.2-6.3c2.1-1.4,4.9-0.8,6.3,1.2C20.9,16.3,20.3,19.1,18.2,20.5z"
      />
      <circle cx="23" cy="25.7" r="3.1" />
      <circle cx="9.2" cy="25.9" r="3.1" />
    </g>
  </svg>
);

export default AurasIcon;
