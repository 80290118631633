import { Player } from "@lottiefiles/react-lottie-player";
import ForgeIcon from "./Icons/ForgeIcon.js";
import forgingAnim from "./forging.json";

const BlacksmithActive = ({
  activeCraftItem,
  activeCraftItemInfo,
  setActiveCraftItem,
  setActiveCraftItemInfo,
  motion,
  hasRequiredItem,
  localProfile,
  itemInfoList,
  handleCraft,
  craftingAnimation,
  t,
}) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        className="buy-confirm-window"
      >
        <div className="forge-container">
          <img className="forge-divider" src="Divider.webp"></img>
          <div className="forge-top-container">
            <img className="forge-image" src={activeCraftItem.miniimg}></img>
            <div className="forge-title-container">
              <div className="forge-title">{t(activeCraftItem.title)}</div>
              <div className="forge-description">
                {activeCraftItemInfo?.luckBonus > 0 && (
                  <span>
                    {t("base luck")} +{activeCraftItemInfo?.luckBonus} <br></br>
                  </span>
                )}
                {activeCraftItemInfo?.speedBonus > 0 && (
                  <span>
                    {t("spin time")} -{activeCraftItemInfo?.speedBonus / 1000}{" "}
                    {t("sec")}
                    <br></br>
                  </span>
                )}
                {activeCraftItemInfo?.type === "craft" && (
                  <span>{t("need for crafting")}</span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              marginBottom: "14px",
              color: "yellow",
            }}
          >
            {t("Ingridients")}
          </div>
          <div className="forge-need-wrapper">
            {itemInfoList.map((item, index) => {
              return (
                <div className="forge-need-container" key={index}>
                  <div
                    style={{
                      backgroundColor: hasRequiredItem(item) ? "yellow" : "red",
                    }}
                    className="forge-item-quantity"
                  >
                    {item.quantity}
                  </div>
                  <img
                    className="forge-need-img"
                    src={item?.itemInfo?.miniimg}
                  ></img>
                  <div className="forge-need-title">{t(item.title)}</div>
                </div>
              );
            })}
            <div className="forge-need-container">
              <div
                style={{
                  backgroundColor:
                    localProfile.attributes.ducks >= activeCraftItem.cost
                      ? "yellow"
                      : "red",
                }}
                className="forge-item-quantity"
              >
                {activeCraftItem.cost}
              </div>
              <img src="ducks-image.webp" className="forge-need-img"></img>
              <div className="forge-need-title">DUCK$</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <button
              onClick={() => {
                setActiveCraftItem(null);
                setActiveCraftItemInfo(null);
              }}
              className="forge-button"
              style={{ backgroundColor: "gray", width: "30%" }}
            >
              {t("BACK")}
            </button>
            <button onClick={handleCraft} className="forge-button">
              {t("CRAFT")}
              {<ForgeIcon size="24px"></ForgeIcon>}
            </button>
          </div>
        </div>
      </motion.div>
      <div
        onClick={() => {
          setActiveCraftItem(null);
          setActiveCraftItemInfo(null);
        }}
        className="black-screen"
        style={{ backgroundColor: "black", opacity: 0.6 }}
      ></div>
      {craftingAnimation && (
        <div className="crafting-anim">
          <Player src={forgingAnim} loop autoplay style={{ height: "250px" }} />
        </div>
      )}
    </>
  );
};
export default BlacksmithActive;
