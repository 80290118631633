import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { getRarity, getRarityColor, getAuraByName } from "./Functions";
import DucksIcon from "./Icons/DucksIcon";

const AuraItem = ({
  t,
  aura,

  onClick,

  opacity,
}) => {
  const [auraDetails, setAuraDetails] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true); // Состояние загрузки изображения

  useEffect(() => {
    const fetchAuraDetails = async () => {
      const details = await getAuraByName(aura.title);
      setAuraDetails(details);
    };
    fetchAuraDetails();
  }, [aura.title]);

  if (!auraDetails) return null;

  const bgColor = getRarityColor(getRarity(aura.probability));

  return (
    <motion.div
      onClick={onClick}
      style={{ backgroundColor: bgColor, opacity: opacity }}
      className="table-item"
    >
      <div className="table-item-name">{t(aura.title)}</div>

      <div
        className="table-item-img-container"
        style={{ position: "relative" }}
      >
        {isImageLoading && (
          <div className="spinner" style={{ position: "absolute", inset: 0 }}>
            <div className="loader"></div>
          </div>
        )}
        <img
          className="table-item-img"
          width={"100%"}
          src={auraDetails.miniimg}
          alt={`${aura.title} image`}
          style={{ display: isImageLoading ? "none" : "block" }}
          onLoad={() => setIsImageLoading(false)} // Изображение загружено
          onError={() => setIsImageLoading(false)} // Ошибка загрузки
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="table-item-cost">
          {aura.probability}
          <DucksIcon size={"14px"} />
        </div>
        <div className="table-item-quantity">{aura.quantity}</div>
      </div>
    </motion.div>
  );
};

export default AuraItem;
