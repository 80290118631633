import AuraItem from "./AuraItem.js";
import { getAuraByName } from "./Functions.js";
import { s_auraMenu } from "./Sounds.js";

const AurasInventory = ({
  t,
  aurasQuantity,
  aurasData,
  motion,
  setActiveAuraIndex,
  sortedAuras,
  soldIndex,
  setActiveItem,
}) => {
  return (
    <>
      <div className="inventory-title">
        <div>{t("Your auras")}</div>
        <div className="intentory-title-quantity">
          {aurasQuantity}/{aurasData.length}
        </div>
      </div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="inventory-container add-ui-container"
        onClick={() => setActiveAuraIndex(null)} // Сброс при клике на фон
      >
        {sortedAuras.length > 0 && (
          <div className="inventory-table">
            {sortedAuras.map((aura, index) => (
              <AuraItem
                t={t}
                opacity={soldIndex === index ? 0.3 : 1}
                key={index}
                aura={aura}
                onClick={async (event) => {
                  if (soldIndex === null) {
                    console.log(soldIndex);
                    event.stopPropagation(); // Останавливаем событие на фон
                    let auraInfo = await getAuraByName(aura.title);
                    auraInfo = {
                      ...auraInfo,
                      type: "aura",
                      quantity: aura.quantity,
                      index: index,
                    };
                    setActiveItem(auraInfo);
                  }
                }}
              />
            ))}
          </div>
        )}
        {sortedAuras.length === 0 && (
          <div style={{ textAlign: "center", padding: "30px 10px" }}>
            {t("empty")}
          </div>
        )}
      </motion.div>
    </>
  );
};
export default AurasInventory;
