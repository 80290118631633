import { s_auraMenu } from "./Sounds";
import InventoryItem from "./InventoryItem.js";

const ItemsInventory = ({
  t,
  motion,
  items,
  activeItemDetails,
  setActiveItem,
  handL,
  handR,
}) => {
  return (
    <>
      <div className="inventory-title">
        <div>{t("Inventory")}</div>
      </div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="inventory-container add-ui-container"
      >
        {items.length > 0 && (
          <div className="inventory-table">
            {items.map((item, index) => (
              <InventoryItem
                t={t}
                key={index}
                item={item}
                onClick={() => {
                  s_auraMenu.play();
                  activeItemDetails(items[index], items[index].quantity);
                }}
              />
            ))}
          </div>
        )}
        {items.length === 0 && (
          <div style={{ textAlign: "center", padding: "30px 10px" }}>
            {t("empty")}
          </div>
        )}
      </motion.div>

      <div className="inventory-container equipped-items-container">
        {t("wearing")}:
        {handL && (
          <div
            className="equipped-item"
            style={{ backgroundImage: `url(${handL.miniimg})` }}
            onClick={() => {
              s_auraMenu.play();
              setActiveItem(handL);
            }}
          >
            L
          </div>
        )}
        {handR && (
          <div
            className="equipped-item"
            style={{ backgroundImage: `url(${handR.miniimg})` }}
            onClick={() => {
              s_auraMenu.play();
              setActiveItem(handR);
            }}
          >
            R
          </div>
        )}
      </div>
    </>
  );
};
export default ItemsInventory;
