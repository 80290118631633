import React, { useState, useRef } from "react";
import "./SlotMachine.css";
import "../App.css";
import loosingPhrases from "./SlotPhrases";
import { motion } from "framer-motion";
import axios from "axios";
import DucksIcon from "../Icons/DucksIcon";
import io from "socket.io-client";
import {
  s_joeError,
  s_joeLoose,
  s_joeSpinning,
  s_joeWin,
  s_joeWin2,
  s_slotEnd,
  s_slotsButton,
  s_slotsStart,
} from "../Sounds";

const API_TOKEN =
  "4db3c3cfb1782a73b68a21eb3c1982fb8eda9bfe6a9fd4008195b4115cc7acbe7d5eccbfacb76675a1bd92cf9b0524dda742cd1fe8186b69b42cf6aa6ecfdc90f76a366277cd47bc3cba3928feac7930ed90d9c316cc265461fbc03a57aa77bd3ad5d67a68a6487b3ec47f61f67f248c8c34b8ceb5d3464720de64a86c215073";

const axiosInstance = axios.create({
  baseURL: "http://localhost:1337/api",
  timeout: 1000,
  headers: {
    Authorization: `Bearer ${API_TOKEN}`,
  },
});

const iconMap = [
    "DUCK$",
    "0",
    "inyan",
    "0",
    "triforce",
    "0",
    "ufo",
    "0",
    "dino",
    "0",
    "doge",
    "0",
    "star",
    "0",
  ],
  // Height of one icon in the strip
  icon_height = 79,
  // Number of icons in the strip
  num_icons = 14,
  // Max-speed in ms for animating one icon down
  time_per_icon = 50,
  // Holds icon indexes
  indexes = [0, 0, 0];

const roll = (reel, offset = 0) => {
  // Minimum of 2 + the reel offset rounds
  const delta =
    (offset + 2) * num_icons + Math.round(Math.random() * num_icons);

  // Return promise so we can wait for all reels to finish
  return new Promise((resolve, reject) => {
    const style = getComputedStyle(reel),
      // Current background position
      backgroundPositionY = parseFloat(style["background-position-y"]),
      // Target background position
      targetBackgroundPositionY = backgroundPositionY + delta * icon_height,
      // Normalized background position, for reset
      normTargetBackgroundPositionY =
        targetBackgroundPositionY % (num_icons * icon_height);

    // Delay animation with timeout, for some reason a delay in the animation property causes stutter
    setTimeout(() => {
      // Set transition properties ==> https://cubic-bezier.com/#.41,-0.01,.63,1.09
      reel.style.transition = `background-position-y ${
        (8 + 1 * delta) * time_per_icon
      }ms cubic-bezier(.41,-0.01,.73,1.03)`;
      // Set background position
      reel.style.backgroundPositionY = `${
        backgroundPositionY + delta * icon_height
      }px`;
    }, offset * 150);

    // After animation
    setTimeout(() => {
      s_slotEnd.play();
      if (offset === 2) {
        s_joeSpinning.stop();
      }

      // Reset position, so that it doesn't get higher without limit
      reel.style.transition = `none`;
      reel.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
      // Resolve this promise
      resolve(delta % num_icons);
    }, (8 + 1 * delta) * time_per_icon + offset * 150);
  });
};

const SlotMachine = ({ localProfile, t, sendSpinning, farm }) => {
  const rolling = useRef(false);

  const [debugText, setDebugText] = useState();

  const [bet, setBet] = useState(10);

  const changeDucksCount = (count) => {
    farm(count);
    // try {
    //   socket.emit("ducksChange", {
    //     playerId: localProfile.id,
    //     count: count,
    //   });
    // } catch (error) {
    //   console.log("ERROR DUCKS FARM ", error);
    // }
  };

  const tryRoll = () => {
    s_slotsButton.play();
    if (rolling.current === false) {
      if (localProfile.attributes.ducks < bet) {
        setDebugText("Недостаточно монет");
        s_joeError.play();
      } else {
        rollAll();
      }
    }
  };

  function rollAll() {
    s_slotsStart.play();
    s_joeSpinning.play();
    rolling.current = true;
    sendSpinning(true);
    changeDucksCount(-bet);
    setDebugText("rolling");

    const reelsList = document.querySelectorAll(".slots > .reel");

    Promise

      // Activate each reel, must convert NodeList to Array for this with spread operator
      .all([...reelsList].map((reel, i) => roll(reel, i)))

      // When all reels done animating (all promises solve)
      .then((deltas) => {
        // add up indexes
        deltas.forEach(
          (delta, i) => (indexes[i] = (indexes[i] + delta) % num_icons)
        );
        const debugText = indexes.map((i) => iconMap[i]).join(" - ");
        console.log(debugText);

        let winCls;
        let win;
        let winMultiplyer = 0;

        // Win conditions
        if (indexes[0] === indexes[1] && indexes[1] === indexes[2]) {
          win = true;
          let index = indexes[0];

          winCls = "win2";

          switch (index) {
            case 8:
              winMultiplyer = 25;
              break;
            case 10:
              winMultiplyer = 50;
              break;
            case 12:
              winMultiplyer = 75;
              break;
            case 6:
              winMultiplyer = 100;
              break;
            case 2:
              winMultiplyer = 250;
              break;
            case 4:
              winMultiplyer = 500;
              break;
            case 0:
              winMultiplyer = 1000;
              winCls = "win1";
              break;

            default:
              winCls = "";
              win = false;
              break;
          }
          setDebugText(`${t("win")} ${bet * winMultiplyer} DUCKS`);
          s_joeWin2.play();
        } else if (
          (indexes[0] === indexes[1] && indexes[0] === 4) ||
          (indexes[1] === indexes[2] && indexes[1] === 4) ||
          (indexes[0] === indexes[2] && indexes[0] === 4)
        ) {
          win = true;
          winMultiplyer = 5;
          s_joeWin.play();
          setDebugText(`${t("win")} ${bet * 5} DUCKS`);
          winCls = "win1";
        } else if (indexes[0] === 4 || indexes[1] === 4 || indexes[2] === 4) {
          win = true;
          winMultiplyer = 2;
          setDebugText(`${t("win")} ${bet * 2} DUCKS`);
          s_joeWin.play();
          winCls = "win1";
        }

        if (win) {
          changeDucksCount(bet * winMultiplyer);
          document.querySelector(".debug").classList.add(winCls);
          setTimeout(() => {
            document.querySelector(".debug").classList.remove(winCls);
            rolling.current = false;
          }, 2000);

          sendSpinning(false);
        } else {
          s_joeLoose.play();
          rolling.current = false;
          sendSpinning(false);
          const debugPhrase =
            loosingPhrases[Math.floor(Math.random() * loosingPhrases.length)];
          setDebugText(debugPhrase);
        }
      });
  }

  const [multiplyer, setMultiplyer] = useState(1);
  const minBet = 10;

  const multiplyerButton = () => {
    s_slotsButton.play();
    multiplyer <= 100 ? setMultiplyer(multiplyer * 10) : setMultiplyer(1);
    return;
  };

  return (
    <div className="main-ui">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          paddingBottom: "10px",
        }}
      >
        <div id="debug" className="debug">
          <div className="debug-text">
            {debugText ? t(debugText) : t("hello")}
          </div>
        </div>
        <div className="slots">
          <div className="reel"></div>
          <div className="reel"></div>
          <div className="reel"></div>
        </div>
      </div>

      <div className="control-panel">
        <div className="counters">
          <div className="counter-container">
            <div className="counter-heading counter-heading-have">
              <div className="counter-heading-text">{t("balance")}</div>
            </div>
            <div className="counter-text">
              <motion.div
                key={localProfile ? localProfile?.attributes?.ducks : ""}
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
              >
                {
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {localProfile?.attributes.ducks}
                    <DucksIcon
                      style={{ marginLeft: "5px" }}
                      size={"16px"}
                    ></DucksIcon>
                  </div>
                }
              </motion.div>
            </div>
          </div>
          <div className="counter-container">
            <div className="counter-heading">
              <div className="counter-heading-text">{t("bet")}</div>
            </div>
            <div className="counter-text">
              <motion.span
                key={bet}
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {bet}
                  <DucksIcon
                    style={{ marginLeft: "5px" }}
                    size={"16px"}
                  ></DucksIcon>
                </div>
              </motion.span>
            </div>
          </div>
        </div>

        <div className="b-settings-container">
          <motion.button
            initial={{ y: -4 }}
            whileTap={{ y: 2 }}
            transition={{ duration: 0.1 }}
            onClick={multiplyerButton}
            className="b-settings b-color-multiplyer"
          >
            x{multiplyer}
          </motion.button>
          <motion.button
            initial={{ y: -4 }}
            whileTap={{ y: 2 }}
            transition={{ duration: 0.1 }}
            onClick={() => {
              s_slotsButton.play();
              if (minBet * multiplyer <= bet - minBet) {
                setBet(bet - minBet * multiplyer);
              } else {
                setBet(minBet);
              }
            }}
            className="b-settings"
          >
            -
          </motion.button>
          <motion.button
            initial={{ y: -4 }}
            whileTap={{ y: 2 }}
            transition={{ duration: 0.1 }}
            onClick={() => {
              s_slotsButton.play();
              if (bet + minBet * multiplyer <= localProfile.attributes.ducks) {
                setBet(bet + minBet * multiplyer);
              } else {
                localProfile.attributes.ducks >= minBet
                  ? setBet(localProfile.attributes.ducks)
                  : setBet(minBet);
              }
            }}
            className="b-settings"
          >
            +
          </motion.button>
        </div>

        <motion.button
          initial={{ y: -4 }}
          whileTap={{ y: 2 }}
          transition={{ duration: 0.1 }}
          className="b-roll"
          onClick={() => {
            tryRoll();
          }}
        >
          {t("roll")}
        </motion.button>
      </div>
    </div>
  );
};

export default SlotMachine;
