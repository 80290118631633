const InventoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M5.2,8.1C4.4,7.1,3,9,2.8,7.7C2.5,5.8,3.4,4.7,4.9,4c2.4-1.1,6.7-0.3,9.4,0c3.5,0.6,10.1-1.6,13.1,0.2
		c1.3,0.8,1.2,3.5,0.6,3.9c-0.6,0.4-1.3-0.4-1.5,0.2c-0.9,3.1-3,9.6-4.9,9.9c-2.1,0.3-5.6-0.6-12.2-0.2C6.1,17.4,7.5,11,5.2,8.1z"
      />
      <path
        d="M4.3,10.9c0.2-0.5,0.9-0.4,1,0.1c0.3,2.6,0.6,5.2,1.1,7.8c0.4,0.9,1.5,0.6,2.4,0.6c0.3,0,0.5,0.2,0.5,0.6
		c-0.1,1.1-0.2,2.7-0.2,3.5c0,0.3,0.2,0.5,0.5,0.5L12,24c0.3,0,0.5-0.2,0.5-0.5c0-0.8,0-2.5,0-3.6c0-0.3,0.2-0.5,0.5-0.5h5.9
		c0.3,0,0.5,0.2,0.5,0.5c0,1,0,2.4,0,3.1c0,0.3,0.2,0.5,0.5,0.5l2.4,0c0.3,0,0.5-0.3,0.5-0.6c-0.1-0.7-0.1-2.1-0.2-3
		c0-0.3,0.2-0.6,0.5-0.5c1,0,2.2,0.3,2.5-0.7c0.5-2.5,0.7-5,1.1-7.6c0.1-0.5,0.8-0.6,1-0.1c2,5.1,1.9,11,2.1,16.4
		c0,0.1,0,0.2-0.1,0.2c-0.7,0.3-1.5,0.6-2.3,0.7c-7.7,1.3-15.9,1.4-23.6-0.2c0,0,0,0-0.1,0C3.4,28,3,27.8,2.7,27.7
		c-0.3-0.1-0.5-0.4-0.5-0.7c0.1-3.3,0.2-6.5,0.7-9.8C3.1,15,3.4,12.8,4.3,10.9z"
      />
    </g>
  </svg>
);

export default InventoryIcon;
