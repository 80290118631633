const SellIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.size}
    height={props.size}
    fill="currentColor"
    {...props}
  >
    <g>
      <path
        d="M14.4,31.5c-4.3-0.5-8.6-2.1-11-6c-0.1,1.5-2.1,3.7-3.2,1.8c-0.6-1.7,0.6-3.6,0.7-5.4c0.7-1.6-0.4-3.9,1-5.1
		c0.8-0.4,2-1.8,2.9-0.9c1.4,1.9,1.7,5.5,4.5,5.7c1.4-0.1,1.1,2.3,0.2,2.9c-0.4,0.7-2.5-0.1-1.1,0.9c7.1,4.4,11.7,3,17.6-2.4
		c1.1-0.7,1-2,1.3-3.1c0.7-1.6,2.2,0,1.1,1.1c-0.4,0.5-0.9,1.1-0.8,1.7c0.5-0.5,1.7-1.2,2-0.3C30.3,29,19.5,31.9,14.4,31.5z"
      />
      <path
        d="M23.8,7C18.3,2.5,11.6,2.8,8.1,9.2C6.6,12.1,5.8,9,7.8,7.7C9.2,5.4,4,9,4.4,11.4c-0.5,2.7-4.7,1.7-3.8-1
		c4.5-9.6,19.2-14,26.5-5.1c1.8,3.5,1.1-0.1,4,0C32,7.1,32.9,17.1,30,17.1c-2.6-0.8-4.1-4-6.3-5.6C20.8,9.7,20.6,8.3,23.8,7z"
      />
      <path d="M30.7,22.3c0,0.6-0.5,0.7-0.7,0.1C30,21.9,30.5,21.8,30.7,22.3z" />
      <path d="M29.8,21.7c-0.3-0.4-0.2-0.5,0.1-0.7C30.2,21.2,30,21.3,29.8,21.7z" />
    </g>
  </svg>
);

export default SellIcon;
