// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ToastStyles.css */
.toast-container {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 9999;
  width: 80%;
  transform: translate(-50%, 0);
}

.toast {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: 0.2s ease;
}

.toast.success {
  background-color: #53b63f;
  color: #cfcfcf;
}

.toast.error {
  background-color: #9b271f;
  color: #cfcfcf;
}

.toast.info {
  background-color: #2974b1;
  color: #cfcfcf;
}

.toast.warn {
  background-color: #ff9800;
  color: #cfcfcf;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Toast/Toast.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,eAAe;EACf,UAAU;EACV,SAAS;EACT,aAAa;EACb,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,4BAA4B;EAC9B;AACF","sourcesContent":["/* ToastStyles.css */\r\n.toast-container {\r\n  position: fixed;\r\n  top: 100px;\r\n  left: 50%;\r\n  z-index: 9999;\r\n  width: 80%;\r\n  transform: translate(-50%, 0);\r\n}\r\n\r\n.toast {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 10px 20px;\r\n  margin-bottom: 10px;\r\n  border-radius: 5px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  opacity: 1;\r\n  transition: 0.2s ease;\r\n}\r\n\r\n.toast.success {\r\n  background-color: #53b63f;\r\n  color: #cfcfcf;\r\n}\r\n\r\n.toast.error {\r\n  background-color: #9b271f;\r\n  color: #cfcfcf;\r\n}\r\n\r\n.toast.info {\r\n  background-color: #2974b1;\r\n  color: #cfcfcf;\r\n}\r\n\r\n.toast.warn {\r\n  background-color: #ff9800;\r\n  color: #cfcfcf;\r\n}\r\n\r\n@keyframes fadeIn {\r\n  from {\r\n    opacity: 0;\r\n    transform: translateY(-20px);\r\n  }\r\n  to {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n@keyframes fadeOut {\r\n  from {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n  }\r\n  to {\r\n    opacity: 0;\r\n    transform: translateY(-20px);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
