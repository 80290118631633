const shopItems = [
  {
    title: "Small Lucky Potion",
    color: "#3e7f33",
    miniimg: "potion-images/120px/luck1x1.webp",
    cost: 1000,
    type: "potion",
  },

  {
    title: "Medium Lucky Potion",
    color: "#3e7f33",
    miniimg: "potion-images/120px/luck2x1.webp",
    cost: 4500,
    type: "potion",
  },
  {
    title: "Large Lucky Potion",
    color: "#3e7f33",
    miniimg: "potion-images/120px/luck3x1.webp",
    cost: 8000,
    type: "potion",
  },

  {
    title: "Small Speed Potion",
    color: "#1a68c8",
    miniimg: "potion-images/120px/speed1x1.webp",
    cost: 1000,
    type: "potion",
  },

  {
    title: "Medium Speed Potion",
    color: "#1a68c8",
    miniimg: "potion-images/120px/speed2x1.webp",
    cost: 4500,
    type: "potion",
  },
  {
    title: "Large Speed Potion",
    color: "#1a68c8",
    miniimg: "potion-images/120px/speed3x1.webp",
    cost: 8000,
    type: "potion",
  },
];
export default shopItems;
